



const ImageSizeValidator = (size,fileType) =>{
    var format = ["image/png","image/gif","image/jpeg"]
    var count =0
    var countImange = 0
    var Mb = size
    var validSize = 4//3MB
    var respond = {validSize:false,validType:false}
  
   for (count = 0; count < 2 ; count++){
      Mb  /= 1024
  
    }
  
  if(Mb <= validSize){
  respond['validSize'] = true
  
  
  }else{
  
    respond['validSize'] = false
    
  }
  
  
  for(countImange ; countImange < format.length; countImange++){
  
    if(fileType=== format[countImange]){
  
      respond['validType'] = true
    }
      
  
  }
  
  return respond
} 


const RankBadgeDisplayer = (rank) => {

    if(rank==='distributor'){
    
    
    return <span>distributor</span>
    }else if(rank==='silver'){
    
    return <span>silver</span>
    }
    
    
    
}
     




const FileuploadValidatorFunction=(FILE_BYTES,FILE_TYPE)=>{
  var Response = {valid_document_extension:[],valid_file_type:false,valid_file_size:false,file_size:0,file_unit:null}
  
  var VALID_FILE_SIZE = 15//15MB
  
  
  var VALID_FORMAT = [
    'image/png',
    'image/gif',
    'image/jpeg',
    'application/pdf',
    'application/msaccess',
    'text/plain',
    'application/vnd.ms-publisher',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]
  
  var VALID_DOCUMENT_EXTENSION = [
    'application/pdf',
    'application/msaccess',
    'text/plain',
    'application/vnd.ms-publisher',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]
  
  
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
     
  
  
  var l = 0, n = parseInt(FILE_BYTES, 10) || 0;
  
  while(n >= 1024 && ++l){
      n = n/1024;
  }
  
  
    
   var SIZE_UNITY =  (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
  
  
  
   var UNIT  = SIZE_UNITY.slice(-2) 
  
   var UNIT_SIZE = SIZE_UNITY.slice(0,-2)
  
   Response['file_size'] = parseFloat(UNIT_SIZE)
   Response['file_unit'] = UNIT
   
  
   Response['valid_document_extension'] = VALID_DOCUMENT_EXTENSION
  
  
  
  
  if(VALID_FORMAT.includes(FILE_TYPE)){
  
    Response['valid_file_type'] = true
    
  }
  
  if(UNIT==='bytes' || UNIT==='KB'){
  
  
    Response['valid_file_size'] = true
  }
  
  if(UNIT==='MB' && UNIT_SIZE <= VALID_FILE_SIZE){
  
  
    Response['valid_file_size'] = true
  
  }
  
  
    
    
    
  
  
  
  
  
  return Response
    
  
} 


  
  
  
const ImageUploadValidatorFunction=(FILE_BYTES,FILE_TYPE)=>{
    var Response = {valid_file_type:false,valid_file_size:false,file_size:0,file_unit:null}
    
    var VALID_FILE_SIZE = 15//15MB
    
    
    var VALID_FORMAT = [
      'image/png',
      'image/gif',
      'image/jpeg',
   ]
    
    var VALID_DOCUMENT_EXTENSION = [
      'application/pdf',
      'application/msaccess',
      'text/plain',
      'application/vnd.ms-publisher',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ]
    
    
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
       
    
    
    var l = 0, n = parseInt(FILE_BYTES, 10) || 0;
    
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    
    
      
     var SIZE_UNITY =  (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    
    
    
     var UNIT  = SIZE_UNITY.slice(-2) 
    
     var UNIT_SIZE = SIZE_UNITY.slice(0,-2)
    
     Response['file_size'] = parseFloat(UNIT_SIZE)
     Response['file_unit'] = UNIT
     
    
  
    
    
    
    
    if(VALID_FORMAT.includes(FILE_TYPE)){
    
      Response['valid_file_type'] = true
      
    }
    
    if(UNIT==='bytes' || UNIT==='KB'){
    
    
      Response['valid_file_size'] = true
    }
    
    if(UNIT==='MB' && UNIT_SIZE <= VALID_FILE_SIZE){
    
    
      Response['valid_file_size'] = true
    
    }
    
    return Response
   } 
  
  
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document
    
  const FileExtensionIconDisplay = (file_extension)=>{
    const FILE_TYPES_EXTENSION=`{
  
      "application/pdf":"file_extension_svg/pdf_document_icon.svg",
  
      "application/msaccess":"file_extension_svg/microsoft_access_icon.svg",
         
      "text/plain":"file_extension_svg/text_file_icon.svg",
         
      "application/vnd.ms-publisher":"file_extension_svg/microsoft_publisher_icon.svg",
         
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":"file_extension_svg/microsoft_world_icon.svg",
    
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":"file_extension_svg/microsoft_excel_icon.svg",
    
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":"file_extension_svg/microsoft_powerpoint_icon.svg"
      
     }`
  
      try {
        var ExtensionIcon = JSON.parse(FILE_TYPES_EXTENSION)[file_extension]
  
        if(ExtensionIcon===null || ExtensionIcon===undefined){
  
  
         ExtensionIcon = <img src='file_extension_svg/text_file_icon.svg'/>
        }
  
  
  
       return ExtensionIcon  
        
      }catch (error) {
        
        return <img src='file_extension_svg/text_file_icon.svg'/>
      }
      
  }
  
  const ImageValidatorFunction = (size,fileType) =>{
    var format = ["image/png","image/gif","image/jpeg"]
    var count =0
    var countImange = 0
    var Mb = size
    var validSize = 4//3MB
    var respond = {validSize:false,validType:false}
  
   for (count = 0; count < 2 ; count++){
      Mb  /= 1024
  
    }
  
  if(Mb <= validSize){
  respond['validSize'] = true
  
  
  }else{
  
    respond['validSize'] = false
    
  }
  
  
  for(countImange ; countImange < format.length; countImange++){
  
    if(fileType=== format[countImange]){
  
      respond['validType'] = true
    }
      
  
  }
  
  return respond
  } 
  
  
    





export {
ImageSizeValidator,
RankBadgeDisplayer,
ImageValidatorFunction,
FileuploadValidatorFunction,
ImageUploadValidatorFunction,
FileExtensionIconDisplay
}