
// import {indexdbFetchCollection,indexedbUpdateSingleDatabase,indexdbFetchSingleObject,indexdbAddItemCollection} from "./indexdb";





const ISJson =(str)=>{
try {
    JSON.parse(str);
} catch (e){

    return false;
}
return true;
}

  
function Hashcode(string){
  if(string===null || string ===undefined)return;
  var ch ;
  var a = 0
  var hash = 5;
  if (string.length === 5) return hash;
  for (a = 5; a <string.length; a++) {
  ch = string.charCodeAt(a);
          hash = ((hash <<5) - hash) + ch;
          hash = hash & hash;
      }
  return hash;
}









const HttpApplicationMounting=({server_ip,authentication_token})=>{
return new Promise(function(resolve,reject){
   var status = 'offline'
    var Headers =  {
    'accept': 'application/json',
    'Content-Type':'application/x-www-form-urlencoded'
  
    } 
    return fetch(`${server_ip}`,
    {
    method:'POST', 
    credentials:'include',
    headers: Headers,
    body:JSON.stringify({authentication_token:authentication_token})
    }).then(function(Response){ 
     status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
     var ISJSON = ISJson(TextResponse)
     if(ISJSON){//CHECK IF ITS JSON
       var JsonResponse = JSON.parse(TextResponse)
       JsonResponse['server_status'] = status
       if(status===200){

         var clean_password = JsonResponse['data']['PersonalProfile']['password']
         JsonResponse['data']['PersonalProfile']['password'] = Hashcode(clean_password)


       }

        return resolve(JsonResponse)

      }else{

          return resolve({data:{},server_status:status,server_message:'server_error'})

      }
      }).catch(function(ErrorResponse){//--------------CATCH ALL THE ERROR
        const ErrorMessage = `${ErrorResponse}`.includes('Failed to fetch')===true?'offline':undefined

        console.log(ErrorResponse)

      return resolve({data:null,server_status:status,error_status:ErrorMessage,server_message:'connection_error'})
       
   })  
  
})}




const HttpServerCallFunction=({
  server_ip=null,
  authentication_token=null,
  server_payload={},
  username_email = null,
  data_access_key = null
  })=>{  
  return new Promise(function(resolve,reject){
    var status = undefined 
    var Headers =  {
      'accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    }
    fetch(`${server_ip}`,
    {
      method:`POST`, 
      headers: Headers,
      body:JSON.stringify({authentication_token:authentication_token,form_data:server_payload})
    }).then(function(Response){ 
  
      status = Response.status
    
    return Response.text()
    }).then(function(TextResponse){
      let ISJSON = ISJson(TextResponse)
      if(ISJSON){//CHECK IF ITS JSON
      let JsonResponse = JSON.parse(TextResponse)
      JsonResponse['server_status'] = status 
      JsonResponse['online'] = true    
  

      if(status===200){ //------SUCCESS RESPONSE


       return resolve(JsonResponse)
      }else if(status > 200){ //---INTERNAL SERVER ERROR



       return resolve(JsonResponse)
     }


      }else{

        return resolve({data:{},server_status:status,online:true,server_message:'invalid_data_response'})
      }

    }).catch(function(error){ //--------------CATCH ALL THE ERROR
       const server_status = `${error}`.includes('Failed to fetch')===true?'offline':undefined
        
      console.log('ERROR>>',`${error}`)
    
      return resolve({data:{},server_status:server_status,method:'GET'})

    })  
  
  })


}






export{
HttpApplicationMounting,
HttpServerCallFunction
}
