import React ,{Fragment,useEffect,useContext,useState}from 'react';
import './CompanyObjective.scss';
// import $ from 'jquery';
import {useLocation} from "react-router-dom";
import {Applicationcontext} from "../../applicationcontext";




const  CompanyObjective = ({display_topbanner=true,DisplayFull=true}) => {
  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor} = CompanyProfile

const Location = useLocation()


const [OBJECTIVES_LIST,SET_OBJECTIVES_LIST] = useState([
  {detail:'To collaborate with the government, private sector, local and international financial institutions to build a strong foundation of financial markets education in Tanzania'},
  {detail:'To establish a functionally modern and conducive learning environment for students and staff to learn and develop their academic and career development in capital markets operations'},
  {detail:'To provide on-site and web-based accredited professional education and training programs that will empower recipients with industry-aligned skills'},
  {detail:'To organize capacity building programs, certification courses, online courses and examinations through certification system'},
  {detail:'To organize seminars, workshops, and conferences on the role of financial markets in transforming the local and global economies'},
  {detail:'To conduct research on financial markets involving case studies and offer consultancy services to the industry'},
])



const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT



return()=>{//<<<<<<<<<<<----------------Component Will Unmount


}
},[]) // eslint-disable-line react-hooks/exhaustive-deps






const OBJECTIVES_LIST_MAP= OBJECTIVES_LIST.map(function(object,index){
return(<li>{object.detail}</li>
)
})




return ( ///-------------- RETURN HTML------------------
<Fragment>
 <div className="full" id='CompanyObjective'>
        <div className="heading_main text_align_center">
          <h2><span>SPECIFIC </span> OBJECTIVES</h2>
        </div>

            <ol  type='I' className='objective-list'>
                
                {OBJECTIVES_LIST_MAP}

            </ol>
 </div>

</Fragment>
);

// CompanyObjective.propTypes = {}
          
// CompanyObjective.defaultProps ={}



}
export{CompanyObjective}

