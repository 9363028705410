import React ,{Fragment,useEffect,useRef,useState,useContext}from 'react';
import './Homepage.scss';
import $ from 'jquery';
import {Link,useLocation,useParams} from "react-router-dom";
import {AboutPage} from "../AboutPage/AboutPage";
import {ProgrammePage} from "../ProgrammePage/ProgrammePage";
import {CompanyCoreValue} from "../CompanyCoreValue/CompanyCoreValue";
import {CompanyObjective} from "../CompanyObjective/CompanyObjective";
import {OurpartnerView} from "../OurPartnerView/OurpartnerView";
// import {LearningObjectiveView} from "../LearningObjectiveView/LearningObjectiveView";
import {Applicationcontext} from "../../applicationcontext";




const  Homepage = ({props}) => {

  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor} = CompanyProfile

  const {mobile_xs,tablet_sm} = ResponsiveMediaQuery

const Location = useLocation()
const Parameter = useParams()

const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component




return()=>{//<<<<<<<<<<<----------------Component Will Unmount



}
},[]) // eslint-disable-line react-hooks/exhaustive-deps


return ( ///-------------- RETURN HTML------------------
<Fragment>
  <div className="ulockd-home-slider" id='Homepage'>
    <div className="container-fluid">
      <div className="row">
        <div className="pogoSlider" id="js-main-slider">


        <div className="pogoSlider-slide" style={{backgroundImage: 'url(images/banner_slider-5.png)'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className={mobile_xs===true || tablet_sm===true?'slide_text_mobile':'slide_text'}>
                       <h4>
                        ATTEND UNIQUE JOB-RELATED TRAINING PROGRAMMES IN  FINANCIAL MARKETS 
                        AND BROADCASTING JOURNALISM 
                       </h4>
                    <br />
                    <div className="full center">
                      <Link to="/application" className="contact_bt">Start a Course</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



             
        <div className="pogoSlider-slide" style={{backgroundImage: 'url(images/banner_slider-1.jpg)'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className={mobile_xs===true || tablet_sm===true?'slide_text_mobile':'slide_text'}>
                       <h4>
                       WE PROMOTE FINANCIAL LITERACY AND STOCK MARKET PARTICIPATION
                       WE ENGAGE YOUTH AND WOMEN SMEs AND SAVINGS GROUPS
                       </h4>
                    <br />
                    <div className="full center">
                      <Link to="/application" className="contact_bt">Start a Course</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        
          <div className="pogoSlider-slide" style={{backgroundImage: 'url(images/banner_slider-2.jpg)'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className={mobile_xs===true || tablet_sm===true?'slide_text_mobile':'slide_text'}>
              
                       <h4>
                        OUR PROGRAMMES ARE OFFERED BY HIGHLY EXPERIENCED INSTRUCTORS 
                         AND ENTAIL HANDS-ON INDUSTRY-CRAFTED CURRICULUM
                      </h4>
                    <br />
                    <div className="full center">
                      <a className="contact_bt" href="/application">Start a Course</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="pogoSlider-slide" style={{backgroundImage: 'url(images/banner_slider-3.jpg)'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className={mobile_xs===true || tablet_sm===true?'slide_text_mobile':'slide_text'}>
                       <h4>
                      BUILD A CAREER THAT WILL GET YOU EMPLOYED AS A 
                       CERTIFIED TRADER AND BROKER IN BANKS & STOCKBROKING COMPANIES
                      </h4>
                    <div className="full center">
                      <a className="contact_bt" href="/application">Start a Course</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="pogoSlider-slide" style={{backgroundImage: 'url(images/banner_slider-4.jpg)'}}>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className={mobile_xs===true || tablet_sm===true?'slide_text_mobile':'slide_text'}>
                       <h4>
                        BECOME A SPECIALIST JOURNALIST IN:
                        FINANCIAL MARKETS ANALYSIS
                        MINING POLICIES AND TRAVEL AND TOURISM 

                      </h4>
                    <div className="full center">
                      <a className="contact_bt" href="/application">Start a Course</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        



        </div>
        {/* .pogoSlider */}
      </div>
    </div>
  </div>
  {/* End Banner */}
  {/* section */}
  <div className="section tabbar_menu">
    <div className="container-padding">
      <div className="row">
        <div className="col-md-12" id='homepage-top-tags'>
          <div className="tab_menu">
            <ul>
              <li><a href="#"><span className="icon"><img src="images/i1.png" alt="#" /></span><span>Marketing</span></a></li>
              <li><a href="#"><span className="icon"><img src="images/i2.png" alt="#" /></span><span>Business Analysis</span></a></li>
              <li><a href="#"><span className="icon"><img src="images/i3.png" alt="#" /></span><span>Treasury Bonds</span></a></li>
              <li><a href="#"><span className="icon"><img src="images/i4.png" alt="#" /></span><span>Investment</span></a></li>
              <li><a href="#"><span className="icon"><img src="images/i4.png" alt="#" /></span><span>Trading</span></a></li>
              <li><a href="#"><span className="icon"><img src="images/i1.png" alt="#" /></span><span>Stocks</span></a></li>
           
              <li><a href="#"><span className="icon"><img src="images/i4.png" alt="#" /></span><span>Finance</span></a></li>
              <li><a href="#"><span className="icon"><img src="images/i1.png" alt="#" /></span><span>Knowledge</span></a></li>
           
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* end section */}

  <AboutPage display_topbanner={false} DisplayFull={false} />

  <div className="container-padding">
   <div className="row">
 <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>EXPLORE OUR COURSES </span></h2>
          </div>
          <div className="full" id='explore-course'>
            <p>
            Financial Markets are rapidly developing in Tanzania and offer plenty of opportunities
             for graduates from universities and college to be employed as advisors and analysts in
              stock broking companies, investment centers and banks, tourism promotion boards,
               private companies, research and consultancy firms, and fin techs. However, for 
               graduates to be employed in those firms they must have the requisite qualifications.
                This is the gap that our institute has endeavored to engage with.

              </p>
              
               <Link className="hvr-radial-out button-theme" to={'/application'}>EXPLORE OUR COURSES</Link>
            
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="full">
          <img src="images/explore-course-1.jpg" alt="#" />
        </div>
      </div>
   </div>
   </div>



  <div className="container-padding" id='general-objective-homepage'>
  <div className="row">
     <div className="col-md-7 layout_padding_2">
    <div className="full">
      <div className="heading_main text_align_left">
        <h2><span>General</span> Objectives</h2>
      </div>
      <div className="full">
        <p>
        To prepare the current and next generations of specialized and global-oriented experts of financial
         markets who will be equipped with advanced innovative knowledge and competencies in the field of 
         financial markets domain.
        </p> 
      </div>
      <div className="full">
      </div>
    </div>
  </div>
  <div className="col-md-5">
    <div className="full" id='general-objective-image-frame'>
      <img src="images/general-objective-1.jpg" alt="#" />
    </div>
  </div>
    </div>
    </div>

  <br /><br />
  <div className="container-padding">
  <div className="row">
    <div className="col-md-6">
      <div className="full float-right_img">
        <img src="images/apply-for-admission.jpg" alt="#" />
      </div>
    </div>
    <div className="col-md-6 layout_padding_2">
      <div className="full">
        <div className="heading_main text_align_left">
          <h2><span>A Unique Professional Training Centre</span></h2>
        </div>
        <div className="full">
          <p>
           we are dedicated to fostering expertise in business analysis, financial securities, 
           and stock investments. As a premier educational institution, we provide comprehensive
            courses designed to equip individuals with the knowledge and skills essential for
             success in the competitive realms of business and finance.
            
            </p>
        </div>
      </div>
    </div>
  </div>
  </div>

  <CompanyCoreValue />

  {/* <div className="section margin-top_50">
  <div className="container-padding">
  <CompanyObjective />
  </div>
  </div> */}



  <br /> <br />
  <OurpartnerView />

     




  </Fragment>
);

// AboutPage.propTypes = {}
          
// AboutPage.defaultProps ={}



}
export{Homepage}

