import React ,{Fragment,useEffect,useContext,useState}from 'react';
import './CompanyCoreValue.scss';
import $ from 'jquery';
import {useHistory,useLocation} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {AppContextApi} from "../../AppContext";
import {TopBanner} from "../TopBanner/TopBanner";
import {Link} from "react-router-dom";




const  CompanyCoreValue = ({display_topbanner=true,DisplayFull=true}) => {

  // const APPLICATION_STATE = useContext(Applicationcontext)

  // const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  // const  {CompanyProfile} = UserProfile
  
  // const {theme_maincolor,theme_subcolor} = CompanyProfile



useEffect(()=>{ //COMPONENT DID MOUNT

  $(window).scrollTop(0)//scroll to top of the component







return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps



const [COREVALUE_LIST,SET_COREVALUE_LIST] = useState([
  {title:'Acuity',image:'images/core-value/Acuity.svg',detail:'We think, act, and draw conclusions quickly with intellectual acuity'},
  {title:'Business Etiquettes',image:'images/core-value/Business-Etiquettes.svg',detail:'We treat our clients and customers with respect and care'},
  {title:'Diversity',image:'images/core-value/Diversity.svg',detail:'Diversity is the pillar of our identity, unity, strength and success'},
  {title:'Equality and equity',image:'images/core-value/Equality.svg',detail:'Equity is the pathway to true equality of status, gender, sex, colour, race, and disability'},
  {title:'ESG Cognizant',image:'images/core-value/Cognizant.svg',detail:'We uphold the values of workplace Environmental, Social and Governance (EDG)'},
  {title:'Learners',image:'images/core-value/Learners.svg',detail:'We believe in learning from others and from our own mistakes'},
  {title:'Time Punctuality',image:'images/core-value/Time-Punctuality.svg',detail:'We’re time conscious: we plan, implement, produce and deliver our products and services on time'},
 
])



const COREVALUE_LIST_MAP= COREVALUE_LIST.map(function(object,index){
  return(
    <div className="col-md-3 corevalue-wrapper">
    <div className="full blog_img_popular">
      <img className="img-responsive" src={object.image} alt="#"/> 
      <h4>{object.title}</h4>
        <br />
      <span>{object.detail} </span>
    </div>
  </div>
  )
  })
  






return ( ///-------------- RETURN HTML------------------
<Fragment>
 <div className="section margin-top_50">
 <div className="container-padding">
  <div className="row">
    <div className="col-md-12">
      <div className="full">
        <div className="heading_main text_align_center">
          <h2><span>OUR </span>CORE VALUES</h2>
        </div>
      </div>
    </div>

       {COREVALUE_LIST_MAP}
  
  </div>
</div>


</div>
</Fragment>
);

// CompanyCoreValue.propTypes = {}
          
// CompanyCoreValue.defaultProps ={}



}
export{CompanyCoreValue}

