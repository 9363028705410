import React ,{Fragment,useEffect,useContext}from 'react';
import $ from 'jquery';
import './StockMarketTrendPage.scss';
import {useLocation} from "react-router-dom";
import {TopBanner} from "../TopBanner/TopBanner"
import {Link} from "react-router-dom";
import {Applicationcontext} from "../../applicationcontext";








const  StockMarketTrendPage = ({display_topbanner=true,DisplayFull=true}) => {

  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor} = CompanyProfile
  



  
  






const Location = useLocation()



const {state} = Location


const [STOCK_TREND_LIST, SET_STOCK_TREND_LIST] = React.useState([
  {
   image:'images/stock-trends/1.jpg', 
   title:`Financial Technology (FinTech) Disruption`, 
   content:`The FinTech sector is disrupting traditional banking and financial services. 
   Innovative companies offering digital payment solutions, robo-advisors, 
   and blockchain-based financial services are gaining prominence.`,
   },
   {
    image:'images/stock-trends/2.jpg', 
    title:`Healthcare Innovations`, 
    content:`The healthcare sector is witnessing innovations in biotechnology,
     pharmaceuticals, and telemedicine. Companies leading the charge in medical 
     breakthroughs and digital health solutions are gaining investor attention.`,
    },
    {
      image:'images/stock-trends/3.jpg', 
      title:`Renewed Focus on Value Stocks`, 
      content:`Amid market volatility, there's a renewed interest in value investing.
       Stocks with solid fundamentals, consistent earnings, and strong balance sheets
       are becoming more appealing to risk-conscious investors.`,
    },
  
  ]
  );
  



useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component







return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps




const STOCK_TREND_LIST_MAP = STOCK_TREND_LIST.map(function(object,index){
  return(
    <div className="col-lg-4 col-md-4 col-sm-12 stock-trend-frame" key={index}>
    <div className="full blog_img_popular">
      <img className="img-responsive" src={object.image} alt="#" />
      <div className='title-frame'><span>{object.title}</span></div>
       <span>{object.content}</span>
      {/* <a className="hvr-radial-out button-theme" href='#' onClick={(event)=>{event.preventDefault()}}>Read More</a> */}
    </div>
  </div>
  )
  })




return ( ///-------------- RETURN HTML------------------
<Fragment>
{display_topbanner===true?<div className='topfooter'></div>:null}
   
{display_topbanner===true?<TopBanner TitleName={'STOCK MARKET TRENDS'}/>:null}   
<div className="section margin-top_50" id='StockMarketTrendPage'>
  <div className="container-padding">

   <div className="row">
     
      <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>STOCK MARKETS</span> TRENDS</h2>
          </div>
          <div className="full" id='explore-course'>
            <p>
            What are the trends in stock markets? How trends in financial markets are affect 
            the local financial markets and economy? Trends are identified by trend lines or
             price action that highlight when the price is making higher swing highs and higher
              swing lows for an uptrend, or lower swing lows and lower swing highs for a downtrend.
               In this section we provide a daily update of the trends in the global and local 
               financial markets and explain how those trends impacts upon financial transactions.
              </p>
              {DisplayFull===false?
               <Link className="hvr-radial-out button-theme" to={'/application'}>EXPLORE OUR COURSES</Link>
            :null}
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="full">
          <div className='background-image-frame' style={{backgroundImage:'url(images/stock-market-1.jpg)'}}>
               
               <div className='background-inner-frame' style={{border:`4px solid ${theme_subcolor}`}}>
                  <img src='images/stock-market-2.jpg' alt='' width='100%' height='100%'/>
               </div>
             
          </div>
        </div>
      </div>
        
   

    </div>

<div className="row">
  <div className="col-lg-12">
    <div id="demo" className="carousel slide" data-ride="carousel">
      {/* The slideshow */}
      <div className="carousel-inner">
          
          <div className="row mb-4">
           
            {STOCK_TREND_LIST_MAP}  


          </div>
       

      </div>

      
    </div>
  </div>
</div>


    </div>


   </div>


   
        {/* section */}
    <div className="section contact_section" style={{background: '#12385b'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="heading_main text_align_left">
            <h2><span>A Unique Professional Training Centre <br /> {UserProfile.CompanyProfile.company_motto}</span></h2>
          </div>
           <div className="full float-right_img">
            <img src="images/stock_market-footer.jpg" alt="#" />
          </div>
        </div>

        <div className="layout_padding col-lg-6 col-md-6 col-sm-12">
          <div className="contact_form">
            <form action="contact.html">
              <fieldset>
                <div className="full field">
                  <input type="text" placeholder="Your Name" name="your name" />
                </div>
                <div className="full field">
                  <input type="email" placeholder="Email Address" name="Email" />
                </div>
                <div className="full field">
                  <input type="phn" placeholder="Phone Number" name="Phone number" />
                </div>
                <div className="full field">
                  <textarea placeholder="Massage" defaultValue={""} />
                </div>
                <div className="full field">
                  <div className="center"><button>Send</button></div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>			  
    </div>
  </div>
  {/* end section */}
</Fragment>
);

// StockMarketTrendPage.propTypes = {}
          
// StockMarketTrendPage.defaultProps ={}



}
export{StockMarketTrendPage}

