import React ,{Fragment,useEffect,useContext,useState}from 'react';
import './OurpartnerView.scss';
import $ from 'jquery';
import {useHistory,useLocation} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {Applicationcontext} from "../../applicationcontext";
import {TopBanner} from "../TopBanner/TopBanner";
import {Link} from "react-router-dom";



const  OurpartnerView=() => {



const APPLICATION_STATE = useContext(Applicationcontext)

const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE

const  {CompanyProfile} = UserProfile

const {theme_maincolor,theme_subcolor} = CompanyProfile

const Location = useLocation()

const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component





console.log(CompanyProfile)


return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps



const [OUR_PARTNER_LIST,SET_OUR_PARTNER_LIST] = useState([
  {title:'SS&C Learning Institute',image:'images/our-partners/SSC-LOGO.webp'},
  {title:'NEWTON UNIVERSITY',image:'images/our-partners/NEWTON-UNIVERSITY-LOGO.PNG'},
  {title:'National Institute of Financial Marketing,India',image:'images/our-partners/NIFM-LOGO.jpg'},
  {title:'GLOBAL FINANCIAL MARKETS INSTITUTE',image:'images/our-partners/GFMI-LOGO.png'}, 

  {title:'CRDB BANK',image:'images/our-partners/CRDB.png'}, 
  {title:'MENAR ACADEMY',image:'images/our-partners/MENAR.png'}, 
  {title:'SAWRITERS COLLEGE',image:'images/our-partners/SAWRITERS.png'}, 
  {title:'SHINYANGA PRESS CLUB',image:'images/our-partners/SHINYANGA.jpeg'}, 
  {title:'ORBIT SECURITIES',image:'images/our-partners/ORBIT.png'}, 
  {title:'FINANCIAL MARKETS ASSOCIATION',image:'images/our-partners/FINANCIAL-MARKETS-ASSOCIATION.png'}, 
 ])

 



 const OUR_PARTNER_LIST_MAP=OUR_PARTNER_LIST.map(function(object,index){
  return(
    <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
     <div className="full blog_img_popular partner-frame" style={{paddingBottom:20}}>
          <div className='partner-logo-frame'>
               <img className="img-responsive" src={object.image} alt="#" style={{maxWidth:'90%',maxHeight:'90%'}} />  
           </div>
          <div className='partner-title-frame'><span>{object.title}</span></div>
    </div>
    </div>
  )
})
  





return ( ///-------------- RETURN HTML------------------
<Fragment>
<div className="container-padding">
  <div className="row">
    <div className="col-md-12">
      <div className="full">
        <div className="heading_main text_align_center">
          <h2><span>OUR PARTNERS</span></h2>
        </div>
      </div>
    </div>
  </div>
  <div className="row">
      {OUR_PARTNER_LIST_MAP}
  </div>			  
</div>
</Fragment>
);

// OurpartnerView.propTypes = {}
          
// OurpartnerView.defaultProps ={}



}
export{OurpartnerView}

