import React ,{Fragment,useEffect,useContext,useRef,useState,createRef}from 'react';
import './LoginForm.scss';
import Grid from '@mui/material/Grid';
import {Applicationcontext} from "../../../applicationcontext";
import {HttpServerCallFunction} from "../../../HttpServercall";
import {ImageValidatorFunction,FileuploadValidatorFunction} from "../../../SharedFunction";
import {Select,InputLabel,Divider,Skeleton,Typography} from '@mui/material';
import {AddAPhotoOutlined,Cancel,LibraryAddCheck,DeleteForever, StackedBarChart,QrCode, Phone,Upload} from '@mui/icons-material';
import {Delete,Done} from "@mui/icons-material";

import {FormControl,Button,TextField,ButtonGroup,Paper,Alert} from '@mui/material';
import {useLocation,useParams,useNavigate,Link} from "react-router-dom";
import {MoonLoader} from "react-spinners";
import Cookies from 'js-cookie';








  
  






  const  LoginForm=({navigatetoApplication=function(){alert('pass navigatetoApplication function ')}})=> {

    const COMPONENT_PRIMARY_KEY = 'ADD_STOCK_VIEW'

    const DATABASE_ACCESS_KEY = 'ADD_STOCK_VIEW'

    const ApplicationState = useContext(Applicationcontext)

    const {theme,ResponsiveMediaQuery,UserProfile,Applicationstate} = ApplicationState
    


    const {CompanyProfile,Permission} = UserProfile

    

    const {theme_maincolor} = CompanyProfile

    // const {role,user_type} = Permission

    const {server_ip,authentication_token} = Applicationstate

    // const {mobile_xs,tablet_sm} = ResponsiveMediaQuery

    const Location = useLocation()

    const {pathname} = Location

    const UrlParameter = useParams()

    const Navigate = useNavigate()
   
    var FormAction = pathname.split('/').slice(-1)[0]==='add'?'add':'update'
    

  const [VALID_ADDSTOCK_STATE,SET_VALID_ADDSTOCK_STATE] = useState(()=>{
    var validstate = false
    const {state} = Location
    if(FormAction==='add'){
      if(state !==null && state !==undefined){
        const {LinkFrom} = state
        if(LinkFrom==='view_store'){
          const {data} = state
          if(Object.keys(data).length > 0){
            validstate = true
          }

        }
      
      }

    }

    return validstate
  })
  



    
  const UploadPassportPhotoRef = useRef()

  const UploadCvAttachmentRef = useRef()

  const UploadCertificateRef = useRef()

  const DEFAULT_PASSPORT_PHOTO = 'passportsize_placeholder.PNG'




  const [USER_APPLICATION_FORM,SET_USER_APPLICATION_FORM]=useState(
    {
      username_email:'',
      password1:'',
      password2:'',
      passport_photo:DEFAULT_PASSPORT_PHOTO,
      full_name:'',
      email:'',
      phone_number:'',
      course_type:'0',
      entry_program:'0',
      cv_attachment:[
        // {fileBlob:'CURICURM ATTACHMENT CV',file_name:'CURICURM ATTACHMENT CV',file_type:'pdf',file_size:'12MB'}
      ],
      certificate_attachments:[
        // {fileBlob:'',file_name:'',file_type:'',file_size:''}
      ],
    }
)



const [VALIDATION_MESSAGE,SET_VALIDATION_MESSAGE] = React.useState(null)



const [SERVER_LOADING,SET_SERVER_LOADING] = React.useState(false)

const [SERVER_MESSAGE,SET_SERVER_MESSAGE] = React.useState(null)

const [SERVER_STATUS,SET_SERVER_STATUS] = React.useState(null)


const [AUTHENTICATED,SET_AUTHENTICATED] = React.useState(false)

  
  



const [ImageGallerySpecificationForm,setImageGallerySpecificationForm]=useState({
     image:DEFAULT_PASSPORT_PHOTO,
     caption_description:''
   }
)   

const [DATAFETCH_LOADING ,SET_DATAFETCH_LOADING] = React.useState(true)
  
    
  
  const [CurrentClickedImage,setCurrentClickedImage] = useState({image_type:'',image_index:null})
  
  const [ServerLoading,setServerLoading] = useState(false)
  

  
  const [SALE_QUANTITY_UNIT,SET_SALE_QUANTITY_UNIT] = useState([
      'pc',
      'set',
      'package',
      'catton',
      'box'
  ])
  
const [SEARCH_FILTER ,SET_SEARCH_FILTER] = React.useState({
    search_query:'',
    search_by:'name',
    brand_name:'general',
    category1:'general',
    selected_storeshop:'general',
    filter_zero_negative_stock:'all',
  
  })

  const [SHORT_COURSE_LIST ,SET_SHORT_COURSE_LIST] = React.useState([
    'marketing',
    'OCSEE',
    'ACSzEE',
    'accounts&finance',
    'business administration',
    'journalism',
    'Dev Studies',
    'international politics',
    'economics',
  ])

  const [LONG_COURSE_LIST ,SET_LONG_COURSE_LIST] = React.useState([
    'marketing',
    'OCSEE',
    'ACSzEE',
    'accounts&finance',
    'business administration',
    'journalism',
    'Dev Studies',
    'international politics',
    'economics',
  ])





function extractDate(dateString) {
    return dateString.substring(2, 10);
 }

  
 


  const {PRODUCT_ID,STORE_ID} = UrlParameter
  
useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(0)//scroll to top of the component








return()=>{//<<<<<<<<<<<----------------Component Will Unmount



}
//eslint-disable-next-line react-hooks/exhaustive-deps 
},[])


useEffect(()=>{//----------COMPONENT DID MOUNT
  //$(window).scrollTop(0)//scroll to top of the component
  
  
  
   
      
return()=>{//<<<<<<<<<<<----------------Component Will Unmount
  
  
  
     
  
}
  //eslint-disable-next-line react-hooks/exhaustive-deps 
},[USER_APPLICATION_FORM.product_or_service,USER_APPLICATION_FORM.category1,USER_APPLICATION_FORM.category2])
  






const UserLoginValidationFunction=()=>{
  return new Promise(function(resolve,reject){
   var  JsonResponse = {status:200,message:null}

   if(`${USER_APPLICATION_FORM.username_email}`.length < 5){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL IN USERNAME'

  }else if((USER_APPLICATION_FORM.password1.length< 4 )){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL IN STRONG PASSWORD'

  }
  else{
    JsonResponse['status'] = 200
    JsonResponse['message'] = null

   }
   return resolve(JsonResponse)
  })
}




  const DeleteCVFunction = (index) =>{

    USER_APPLICATION_FORM.cv_attachment.splice(index,1)
    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})
      
  }

 
  
  const DeleteCertificateFunction = (index) =>{

    USER_APPLICATION_FORM.certificate_attachments.splice(index,1)
    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})
      
  }
  
  



    
      
  
const LogginPageReloadButton = document.getElementById('SUCCESS_LOGIN_RELOAD_PAGE')

const loginPageReloadRef = useRef(null)

const UserLoginFunction=()=>{
  UserLoginValidationFunction().then(function(JsonResponse){
    const {status,message} = JsonResponse
    SET_VALIDATION_MESSAGE(message)
    if(status===200){
        SET_SERVER_LOADING(true)
        SET_SERVER_STATUS(null)
        SET_SERVER_MESSAGE(null)
        HttpServerCallFunction({
        server_ip:`${server_ip}api/authentication/user/login`,
        server_payload:USER_APPLICATION_FORM
      }).then(function(JsonResponse){
        const {server_status,server_message}=JsonResponse
        SET_SERVER_LOADING(false)
        SET_SERVER_STATUS(server_status)
        SET_SERVER_MESSAGE(server_message)

        if(server_status===200){

        const {authentication_token} = JsonResponse
        //Create a cookie that expires 7 days from now, valid across the entire site:
        Cookies.set('ux-csrftoken', authentication_token, { expires:1, path: '/' });


          setTimeout(function(){
  
            // LogginPageReloadButton.click()
          //  loginPageReloadRef.current.click()
    
      
           },200)
    
        }
      
    
        console.log(JsonResponse)
    
       
    
      
      })
    }


  })





}


const onchangeSingleValueFunction=(event)=>{
  const {checked,name,type,value} = event.target
  if(type==='checkbox'){

   SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:checked})

  }else if(type==='number'){

    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:parseFloat(value)})

  }else{

    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:value})
 
   }

};





const handleClick = (event) => {
  event.preventDefault();
  navigatetoApplication()
 
};


  
   
return ( //------------------- RETURN HTML
<Fragment> 
<div id='LoginFormFrame' style={{backgroundImage:'url(images/explore-course-1.jpg)'}}>  
<Grid container spacing={2} className='FormLeftRightPaddingFrame'> 

  <Grid item xs={12}> {/*------------ FORMS SECTION ROW ------------------*/}
      <div id='UserFormBackgroundFrame' style={{border:`1px solid ${theme_maincolor}`}}> 
      <form noValidate autoComplete="off" action="" method="post" id='ProductFormElement'>  
        <Grid container spacing={1}> 

        <Grid item xs={12}> 
           <Grid container spacing={1}> 

           <Grid item xs={12}> 
            <div className='LoginHeaderFrame'>
  
                <Typography variant='h6'>LOGIN TO APPLY </Typography>
              

            </div>
           </Grid>

           <Grid item xs={12}> 
           <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label='username_email' 
                variant='filled' 
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.username_email}
                name='username_email'
                fullWidth
                size='small'
                InputLabelProps={{
                 shrink: true
               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.username_email !==null && JSON.stringify(USER_APPLICATION_FORM.email).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
           </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

           <Grid item xs={12}> 
           <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label='password' 
                variant='filled' 
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.password1}
                name='password1'
                fullWidth
                size='small'
                InputLabelProps={{

                 shrink: true

               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.password1 !==null && JSON.stringify(USER_APPLICATION_FORM.password1).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
           </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

           <Grid item xs={12}>
              <div className='validationFrame'>
              
                {VALIDATION_MESSAGE!==null?<Alert severity="error"> {VALIDATION_MESSAGE} </Alert>:null}
              
              </div>
             </Grid>

           <Grid item xs={12}>
              <div className='validationFrame'>

                {SERVER_STATUS === 200 && SERVER_MESSAGE!==null?
                <Alert severity='success'> 
                 

                 <a href='#' onClick={handleClick}> {SERVER_MESSAGE}</a>
                
                </Alert>:null}
                
                {SERVER_STATUS > 200 && SERVER_MESSAGE!==null?<Alert severity="error"> {SERVER_MESSAGE} </Alert>:null}
              
              </div>
            </Grid>

           <Grid item xs={12}> 
            <div className='LoginButtonFrame'>

         

             <Button variant='outlined' disabled={SERVER_LOADING===true?true:false} size='large' onClick={UserLoginFunction}>Login &nbsp; <MoonLoader size={25} color='blue' loading={SERVER_LOADING} /></Button>

             <Link to='/application/signup'><Button size='large'  variant='contained'>Signup</Button> </Link>

            </div>
           </Grid>

             </Grid>
           </Grid>
          </Grid> 
      </form>
      </div>
 </Grid> 


</Grid>
</div>
</Fragment>
);
}
export {LoginForm};
    
    