import React ,{Fragment,useEffect,useContext,useRef}from 'react';
import $ from 'jquery';
import './ApplicationPage.scss';
import {useLocation} from "react-router-dom";
import {TopBanner} from "../TopBanner/TopBanner";
import Grid from '@mui/material/Grid';
import {Link} from "react-router-dom";
import {Applicationcontext} from "../../applicationcontext";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {LoginForm} from "./LoginForm/LoginForm";
import {SignupForm} from "./SignupForm/SignupForm";
import {UserApplicationAccount} from "./UserApplicationAccount/UserApplicationAccount";
import {LockPerson,Lock,TaskAlt,Login,HowToReg} from "@mui/icons-material";








const  ApplicationPage=({display_topbanner=true,DisplayFull=true}) => {

  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery,Applicationstate} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  const {authenticated} = Applicationstate
  
  const {theme_maincolor,theme_subcolor} = CompanyProfile
  

  
  






const Location = useLocation()



const {state} = Location






useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component


return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps




 //Initialize state with value from local storage or a default value
 const [APPLICATION_TAB_SECTION, SET_APPLICATION_TAB_SECTION] = React.useState(() => {
  return localStorage.getItem('APPLICATION_TAB_SECTION') || 'login_tab';
});

const loginPageReloadRef = useRef(null)
//Update local storage whenever APPLICATION_TAB_SECTION changes
useEffect(() => {

localStorage.setItem('APPLICATION_TAB_SECTION', APPLICATION_TAB_SECTION);

},[APPLICATION_TAB_SECTION]);



const handleChange = (event, newValue) => {
   SET_APPLICATION_TAB_SECTION(newValue);
};



const navigatetoApplicationAccount=()=>{

  SET_APPLICATION_TAB_SECTION('application_tab')
  window.location.reload();

  // loginPageReloadRef.current.click()
}



const navigatetoLoginPage=()=>{

  SET_APPLICATION_TAB_SECTION('login_tab')
  
  setTimeout(function(){
  
      window.location.reload();


   },200)


}


const onclickLoginNavigation=()=>{

  SET_APPLICATION_TAB_SECTION('login_tab')


}




return ( ///-------------- RETURN HTML------------------
<Fragment>
{display_topbanner===true?<div className='topfooter'></div>:null}
<a href='/application/application' ref={loginPageReloadRef} id='SUCCESS_LOGIN_RELOAD_PAGE' style={{position:'absolute',visibility:'hidden'}}></a>     
{display_topbanner===true?<TopBanner TitleName={'APPLICATION PORTAL'}/>:null}  

<div className="section margin-top_20">
  <div className="container-padding">
    
    <div className="row about-wrapper">
     <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={APPLICATION_TAB_SECTION}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Login" value="login_tab" icon={<div className='TablistIcon'><Login fontSize='medium'/></div>}/>
            <Tab label="Signup" value="signup_tab" icon={<div className='TablistIcon'><HowToReg fontSize='medium'/></div>}/>
            <Tab label="MyApplication" value="application_tab" icon={<div className='TablistIcon'>{authenticated?<TaskAlt fontSize='medium'/>:<Lock fontSize='medium'/>}</div>}/>
          </TabList>
        </Box>
        <TabPanel value="login_tab" sx={{padding:0.5}}>
          <div className='TabpanelFrame'>
       
            
            <LoginForm navigatetoApplication={()=>(navigatetoApplicationAccount())} />
               
          </div>
          </TabPanel>
        <TabPanel value="signup_tab" sx={{padding:0.5}}>
          <div className='TabpanelFrame'>

               <SignupForm navigatetoLoginPage={onclickLoginNavigation}/>
               
          </div>
        </TabPanel>
         <TabPanel value="application_tab" sx={{padding:0.5}}>
          <div className='TabpanelFrame'>
               
               {authenticated?<UserApplicationAccount navigatetoLoginPageFunction={navigatetoLoginPage} />:<LoginForm navigatetoApplication={()=>(navigatetoApplicationAccount())} />}
               

           </div>
          </TabPanel>
      </TabContext>
    </Box>

  </div>
  </div>
  </div> 
</Fragment>
);

// ApplicationPage.propTypes = {}
          
// ApplicationPage.defaultProps ={}



}
export{ApplicationPage}

