import React ,{Fragment,useEffect}from 'react';
import './TopBanner.scss';
import $ from 'jquery';
import {useHistory,useLocation} from "react-router-dom";
//import {http_worker,indexedb_worker} from "../worker";

//import {makeStyles} from "@material-ui/core";
// import {NotificationImportant} from "@material-ui/icons";

// import Paper from '@material-ui/core/Paper';
// import Grid from '@material-ui/core/Grid';
// import ButtonGroup from '@material-ui/core/ButtonGroup';
// import Checkbox from '@material-ui/core/Checkbox';
// import {Typography,Container} from '@material-ui/core';





const  TopBanner = ({TitleName}) => {


// const useStyles = makeStyles((theme)=>({
//     button:{
//       position:'relative',
//       [theme.breakpoints.down('sm')]:{
//         backgroundColor:'red',
//         width:500
//       }
//     },
//     iconSize:{
//       fontSize:100,
      
      
//     },
//     root: {
//       flexGrow:2
//     },
//     paper: {
//       padding: theme.spacing(5),
//       textAlign: 'center',
//       color: theme.palette.text.secondary,
      
//     },
    
// }
// )) 

//const classes = useStyles()



// const [TopBannerx, setProductDetail] = React.useState(
  
//   {
//   name:'FOREVER ALOE VERA GEL',
//   main_functionality:null,
//   price:0,
//   main_image:'TopBanner/FOREVER-ALOEVERA-GEL.jpg',
//   image_collection:[],
//   description:'Forever Aloe Vera Gel is a pure aloe drink with 99.7% aloe vera gel! The gel comes directly from our own plantations in Texas and the Dominican Republic. Forever Aloe Vera Gel is rich in vitamin C, is sugar-free and contains no preservatives.',
//   feature_inclusive:[]
//   }

// );


const Location = useLocation()

const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT

  //$(window).scrollTop(20)//scroll to top of the component





return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps










return ( ///-------------- RETURN HTML------------------
  <Fragment>
<section className="inner_banner" id='topbanner_background_image' style={{backgroundImage:`url(images/top-footer.jpg)`}}>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="full">
           <div id='topbanner-title-frame'><h3>HOME / {TitleName}</h3></div>
      
        </div>
      </div>
    </div>
  </div>
</section>


    
    </Fragment>
);

// TopBanner.propTypes = {}
          
// TopBanner.defaultProps ={}



}
export{TopBanner}

