import React ,{Fragment,useEffect,useContext,useState}from 'react';
import './AboutPage.scss';
import $ from 'jquery';
import {useHistory,useLocation} from "react-router-dom";
import {Applicationcontext} from "../../applicationcontext";
import {TopBanner} from "../TopBanner/TopBanner";
import {Link} from "react-router-dom";
import {CompanyObjective} from "../CompanyObjective/CompanyObjective";
import {CompanyCoreValue} from "../CompanyCoreValue/CompanyCoreValue";



const  AboutPage = ({display_topbanner=true}) => {


const APPLICATION_STATE = useContext(Applicationcontext)

const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE

const  {CompanyProfile} = UserProfile

const {company_long_name,company_motto} = CompanyProfile

const Location = useLocation()

const {pathname} = Location

const DisplayFull = pathname==='/about'?true:false


useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component







return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps



const [MANAGEMENT_PROFILE_LIST,SET_MANAGEMENT_PROFILE_LIST] = useState([
  {
  name:'Khamis Shaban Mwinyimbegu',
  image:'images/management/Khamis-Shaban.png',
  position:'CO-Founder and Managing Director',
  description:`
  A PhD Candidate at Neptune Institute of Management and Technology (NIMT) New Delhi India,
  Khamis has MA in Public Administration (ISS, NL), MA in International Politics (CERIS, Belgium),
  BA in Public Administration & International Relations (UDSM, Tanzania), PGD International Relations
  (UoN,, Kenya) & Diploma in Management Consultancy (Blackford, UK). He has over 40years of ambidextrous
  leadership experience in management, Finance, HR, diplomacy, education, tourism, consultancy, 
  research & publication.
  `},
  {
    name:'Paull Charles Mabuga',
    image:'images/management/Paull-Charles.png',
    position:'Institute Principal',
    description:`
    A media stalwart and reputable consultant, Paul is a graduate of DW Akademie, Berlin
    Germany, IC Schools Glasgow UK, and Namibia School of Journalism, Windhoek. Paul has over
    20 years of leadership experience in broadcasting journalism, public finance and planning,
    project management, multimedia content planning, program design and development. He is
    also a reporter and producer of documentaries, short and longer reports. He is a certified
    trainer, mentor, program producer, and organizer of tailor-made training courses, seminars,
    webinars and conferences.
    `},
 
])

const [ADVISORY_BOARD_LIST,SET_ADVISORY_BOARD_LIST] = useState([
  {
   name:'Godfrey A Lusanjala',
   image:'images/advisory/godfrey.png',
   position:'Chairman',
   description:`
    Lecturer, Institute of Rural Development Planning (IRDP) Dodoma. PhD Candidate Mzumbe University,
    MBA, CPA-T, Certificate in Introduction to Investment Award (CISI-UK),  Certified  Securities
    Industry Analyst (CMSA-TZ)
  `},
   {
     name:`Prof Jerome Dumetz `,
     image:'images/advisory/Prof-Jerome-Dumetz.png',
     position:`Member`,
     description:`
      Cross-cultural Management Lecturer, Trainer, Consultant at 
      Newton University, Czech. Keynote speaker
      `
    },
        {
          name:'Dr. Sakina M Faru',
          image:'images/advisory/Sakina.png',
          position:'Vice-Chairman',
          description:`
          Lecturer (SAUT) , PhD (magna cum laude), Vechta University,
          Germany, M.A Mass Communication (SAUT), Advanced Diploma (Journalism) (SAUT),
          Certificate Environmental  Journalism (SIDA)
          `
        },      

       {
        name:`Dr. Eng Dennis Sirito Makoi`,
        image:'images/advisory/Dennis-SiritoMakoi.jpg',
        position:`Member`,
        description:`
         Chief Executive Director – Ninety Two Ltd
         Degree in Marine Engineering , Zeevart University in Belgium. 
         Has been Managing Director - Africa Power Investment Ltd 3. 
         Director - Sirito Construction Ltd 4. President – Tanzania Tennis Association 
        `
       },  
       
       {
        name:`Joseph Mayunga Mihangwa`,
        image:'images/advisory/Joseph-Mayunga.png',
        position:`Member`,
        description:`
        Legal, Social and Media
        Consultant, LLB (OUT),
        PGD Finance, PGD Mass
        Communication
        (Australia), Certificates
        in Social Security Law.
        Has held higher
        positions in Government
        (Min of Labour),
        Parastatals (NPF/NSSF)
        &amp; SHIRECU
        `
       }, 
       {
        name:`Hon Dr Pius Steven Chaya (MP) `,
        image:'images/advisory/Hon-Dr-Pius-Steven-Chaya.png',
        position:`Member`,
        description:`
          MP for Manyoni East, PhD Public Policy
          and Planning (UDOM), MSc Public Policy
          Management, MA Public Health, BSc 
          Environmental Sciences Management,
          Public Health expert. Has been Deputy
          Chair Parliamentary Committee Land,
          Natural Resource and Tourism, currently
          member Parliamentary Committee Public
          Investment, Commonwealth
          Parliamentary Association (CPA). Has
          worked in Government, Health sector
          with UNICEF, AMREF, World Vision, and
          was Assistant Lecturer at UDOM &amp;
          Institute of Rural Development Planning
           (IRDP). `
        },
 
])



const MANAGEMENT_PROFILE_LIST_MAP= MANAGEMENT_PROFILE_LIST.map(function(object,index){
  return(
    <div className="col-md-4" key={index}>
    <div className="full blog_img_popular management-frame" style={{paddingBottom:20}}>
         <div className='management-profile'>
           <img className="img-responsive" src={object.image} alt="#" style={{maxWidth:'100%',maxHeight:'100%'}}/>  
         </div>
         <h4>{object.name}</h4>
           <b>({object.position})</b>
        <br />
        <span className='management-description'>
         {object.description}
       </span>
    </div>
    </div>
  )
})





const ADVISORY_BOARD_MAP=ADVISORY_BOARD_LIST.map(function(object,index){
  return(
    <div className="col-12 col-sm-6 col-md-4 col-lg-3" key={index}>
    <div className="full blog_img_popular advisory-frame" style={{paddingBottom:20}}>
         <div className='advisory-profile-frame'>
             <div className='advisory-profile'>
             <img className="img-responsive" src={object.image} alt="#" style={{maxWidth:'90%',maxHeight:'90%'}}/>  
             </div>
          </div>
          <div className='advisory-title-frame'>
                <span className='advisory-name'>{object.name}</span>
                <b>({object.position})</b>
           </div>
        <div className='management-description'>
          <span>{object.description}</span> 
        </div>
    </div>
    </div>
  )
})
  
  







return ( ///-------------- RETURN HTML------------------
<Fragment>
{display_topbanner===true?<div className='topfooter'></div>:null}
{display_topbanner===true?<TopBanner />:null}  

<div className="section margin-top_50">
  <div className="container-padding">
    
    <div className="row about-wrapper">
       <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2>{company_long_name} <span>{company_motto}</span></h2>
          </div>
          <div className="full">
             <b>Who We Are</b>
            <p>
              We are a limited company incorporated in Tanzania with Certificate of Incorporation No.
              176586532 issued on 25th July 2024. Formerly, a registered Business Name on 18th June 2021,
              the company has come of age through steady growth under agile leadership. We are a training 
              provider blended with a multi-national team blessed with exquisite expertise, experience,
              academic records, and authentication.
            </p>
 
            <h4>How We Operate</h4>           
             <p>
             We collaborate with private and public companies and organizations operating in financial 
             markets securities to organize industry-focused training through seminars, meetings workshops
              and conferences. We confer precedence on industrial practical training that expose trainees 
              to the real situation of equity investing and resultant benefits. We endeavor in wide-ranging
               financial literacy and strategic investment options among small and medium companies, AMCOS,
                artisanal miners, women, youth groups, disabled as well as associations of livestock keepers.
                 Our drive is to get the whole population involved in equity investing. 
            </p>


          </div>
          

          <div className="full">
            {DisplayFull===false?
            <Link className="hvr-radial-out button-theme" to={'/about'}>About more</Link>
            :null}
          </div>
        </div>
      </div>

      <div className="col-md-6 layout_padding_2">
        <div className="full">

          <div className="heading_main text_align_left">
            <h2><span>What We Do</span></h2>
          </div>
          <div className="full">
             <img src='images/what-we-do.jpg' style={{maxWidth:'100%'}}/>
            <p>
            We offer a variety of services and products that empower further our clients through 
            career-oriented training, consultancy and expertise advice on results-based investments
             that spur economic growth and development. We offer short and long-term courses ranging
              from certificate to diploma. Our trainings are industry based aiming at transforming 
              students into professionals who can be employed or become self-employed. We also arrange
               oversees education studies for Tanzania students
            </p>
          </div>

          <div className="full">
            {DisplayFull===false?
            <Link className="hvr-radial-out button-theme" to={'/about'}>About more</Link>
            :null}
          </div>
        </div>
      </div>



    </div>

    {DisplayFull===true?
    <div className="row about-wrapper">

      <div className="col-md-6" id='mission-image-section'>
        <div className="full">
          <img src="images/local-business-branding.jpg" alt="#" style={{maxWidth:'80%'}}/>
        </div>
      </div>
       
       <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Local Business </span> Branding </h2>
          </div>
          <div className="full">
            <p> 
             We promote local business through a promotion system that helps these businesses to anchor 
             their respective markets spheres. We help these businesses to connect with equity markets 
             and the wider Tanzanian business landscape. We organize an annual business brand competitions
              that lead to the award of the most recognized and valued brand in Tanzania.
            </p>
          </div>
        </div>
      </div>

      <div className="col-md-12"><div style={{padding:5,width:'100%',height:5,borderBottom:'1px solid gainsboro'}}></div> </div>

      <div className="col-md-8 layout_padding_2" style={{padding:10,borderBottom:'1px solid gainsboro'}}>
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>City </span> Branding </h2>
          </div>
          <div className="full">
            <p> 
            The growth of cities is very important to the growth of financial markets and securities in Tanzania.
             We organize branding of Tanzanian Cities based on their strategic location, economic potentials, 
             growth trends, education centres, standard of living investments, tourism attractions, job creation,
              and the transport ease in reaching the city. This ranking provides local and international investors 
              with broad scope to invest or migrate to live in those cities.
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-4" id='mission-image-section'>
        <div className="full" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
          <img src="images/city-branding.jpg" alt="#" style={{maxWidth:'80%'}}/>
        </div>
      </div>

      <div className="col-md-8 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Local and </span> International Conferences</h2>
          </div>
          <div className="full">
            <p> 
            We organize annual conferences that focus on the contribution of financial markets 
            and securities to the growth of the economy. These conferences bring together local
             and foreign experts, policy makers, investors and the business communities. It is an opportunity that markets Tanzania as an emerging financial market
            </p>
          </div>
        </div>
      </div>
      
 

    </div>
    :null}
     <br />
    {DisplayFull===true?
    <div className="row about-wrapper">       
       <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Our </span> Vision</h2>
          </div>
          <div className="full">
            <p> 
            To be a renowned centre for the study of financial markets 
            and award of globally recognized and accredited professional
            certifications
            </p>
          </div>
        </div>
      </div>
      <div className="col-md-6" id='mission-image-section'>
        <div className="full">
          <img src="images/vision-photo-1.jpg" alt="#" style={{maxWidth:'60%'}}/>
        </div>
      </div>
 
      <div className="col-md-12"><div style={{padding:5,width:'100%',height:5,borderBottom:'1px solid gainsboro'}}></div> </div>

    </div>
    :null}
    
    <br />
    {DisplayFull===true?
    <div className="row about-wrapper">

      <div className="col-md-6" id='mission-image-section'>
        <div className="full">
          <img src="images/mission-image-1.jpg" alt="#" style={{maxWidth:'60%'}}/>
        </div>
      </div>
       
       <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Mission </span> Of {company_long_name}</h2>
          </div>
          <div className="full">
            <p> 
            To offer top-notch education and skills training on financial markets garnered 
            from classroom training, mentorship from industry experts, and industrial placements
             in stock exchanges, financial markets projects, public and private banks.
              We envisage our students practicing job-shadowing in the ministries of finance and investment,
               foreign firms, mutual fund companies, stock broking firms, FinTechs, and other financial
                markets intermediaries.
            </p>
          </div>
        </div>
      </div>
      
 

    </div>
    :null}
     <br />
    {DisplayFull===true?
    <div className="row" style={{borderBottom:'1px solid gainsboro'}}>
     <div className="col-md-8 layout_padding_2">
    <div className="full">
      <div className="heading_main text_align_left">
        <h2><span>General</span> Objectives</h2>
      </div>
      <div className="full">
        <p>
         To prepare the current and next generations of specialized and global-oriented experts of financial
         markets who will be equipped with advanced innovative knowledge and competencies in the field of 
         financial markets domain.
        </p> 
      </div>
      <div className="full">
        {/* <a className="hvr-radial-out button-theme" href="#">Read More</a> */}
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="full" id='general-objectives-image-frame'>
      <img src="images/general-objectives.jpg" alt="#" style={{maxWidth:'80%'}}/>
    </div>
  </div>
    </div>
    :null}

    {DisplayFull===true?
    <div className="row">
     <div className="col-md-12 layout_padding_2">
    <div className="full">
      <div className="heading_main text_align_left">
        <h2><span>The broad focus of our business</span>activities is on</h2>
      </div>
      <div className="full">
            <div className='broad-focus-activities'>
              
              <span><b>-Fund Management</b> 
               (stocks, bonds, debentures, forex, hedge funds, derivatives, loans, investments, remittances, and microfinance)
               </span>
              
              <span><b>-Management Consultancy </b>  
              (advisory services on efficient management of business, start-ups, business/strategic plans, youth and women SMEs, project management) 
              </span>

              <span><b>-market research  </b>  
                 (trends in local and foreign financial markets stock exchanges, bonds, FDI, city economy branding, investment opportunities, barriers and solutions)
              </span>

              <span><b>-	public opinion polling </b> 
                 (carry out polling in collaboration with government, NGOs, private sector on issues of common good to society/nation) 
              </span>

              <span><b>-	educational support services  </b> 
                  (regular and specialized skills training, train teachers, management support/outsourcing to improve performance in failing schools). 
              </span>

            </div>
      </div>
    </div>
  </div>
    </div>
    :null}

    {DisplayFull===true?
    <div className="row">
       
       <CompanyObjective />
      
    </div>
    :null}

  </div>
</div>

    {DisplayFull===true? <CompanyCoreValue /> :null}
 
    {DisplayFull===true?
    <Fragment>
    <div className="container-padding">
    <div className="row">
   
    <div className="col-md-12">
      <div className="full">
        <div className="heading_main text_align_center">
          <h2><span>MANAGEMENT </span></h2>
        </div>
      </div>
    </div>

      {MANAGEMENT_PROFILE_LIST_MAP}
  
    </div>

    </div>
    </Fragment>
    :null}

    {DisplayFull===true?
    <Fragment>
    <div className="container-padding">
    <div className="row">
   
    <div className="col-md-12">
      <div className="full">
        <div className="heading_main text_align_center">
          <h2><span>ADVISORY BOARD</span></h2>
        </div>
      </div>
    </div>

      {ADVISORY_BOARD_MAP}
  
    </div>

    </div>
    </Fragment>
    :null}

    


</Fragment>
);

// AboutPage.propTypes = {}
          
// AboutPage.defaultProps ={}



}
export{AboutPage}

