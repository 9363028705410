import './App.css';
import React ,{useState,useEffect} from 'react';
import {RouteNavigation} from "./RouteNavigation";
import {Link,useLocation} from "react-router-dom";
import {Applicationcontext} from "./applicationcontext";
import {useTheme} from '@mui/material/styles';
import {ThemeProvider,createTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Cookies from 'js-cookie';
import {HttpServerCallFunction} from "./HttpServercall";




function App() {
const ResponsiveDimention = useTheme();
const mobile_xs = useMediaQuery(ResponsiveDimention.breakpoints.only('xs'));
const tablet_sm = useMediaQuery(ResponsiveDimention.breakpoints.only('sm'));
const desktop_md = useMediaQuery(ResponsiveDimention.breakpoints.only('md'));
const desktop_lg = useMediaQuery(ResponsiveDimention.breakpoints.only('lg'));
const desktop_xl = useMediaQuery(ResponsiveDimention.breakpoints.only('xl'));



const [Applicationstate,setApplicationstate] = useState({
  text_dark_color:'#34282C',
  text_light_color:'#A9A9A9',
  super_admin_status:'admin', 
 dark_theme:(function(){
   var theme_state = localStorage.getItem('dark_theme')
   if(theme_state !==null && theme_state !==undefined){
    return JSON.parse(theme_state)
   }else{

   localStorage.setItem('dark_theme','false')   
   return false;
   }   
  })(),
  authentication_token:(function(){
   var token = Cookies.get(`ux-csrftoken`)
   if(token !==null && token !==undefined){
   return token;
   }else{
   return null;
   }   
  })(),
  server_ip:(function(){
     if((process.env.NODE_ENV==='production') && window.location.hostname !=='127.0.0.1' && window.location.hostname!=='localhost'){
      return "/";
     }else{ 
       return  "http://127.0.0.1:8000/";
      }
 
  })(),
  authenticated:false
})
  
const [UserProfile,setUserProfile] = useState({
    CompanyProfile:{
       company_logo:'logo.png',
       company_name:'MIFS',
       company_long_name:'Mwanza Institute of Financial Securities',
       company_motto:'A Unique Professional Training Hub',
       theme_maincolor: "#095a83",
       theme_subcolor: "#07528d",
       theme_decoration_color: "#FF1493",
       CONTACT:{
         phone_number1:'+255742927961',
         phone_number2:'+255688856138',
         whatsap_number:'+255742-927861',
         email_one:'info@mwanzaifs.ac.tz',
         email_two:'admission@mwanzaifs.ac.tz',
        },
       SOCIAL_MEDIA:{
        facebook_handle:'wiflexi_tz',
        facebook_link:'https://',
        instagram_handle:'wiflexi_tz',
        instagram_link:'https://',
       },
       OFFICE_LOCATION:{
        country:'Tanzania',
        region:'Mwanza',
        postal_address:`P.O. Box 9 Mwanza, Tanzania`,
        office_address:`3rd Floor Room 301,
        Nyanza Cooperative Building/KCB Bank 
        Kenyatta Road 
        `,
      }
      },
     PersonalProfile:{
      username_email:null,
      first_name:null,
      last_name:null,
      middle_name:null,
      profile_photo:null,
      primary_email:null,
      user_type:null
     },
     Permission:{
      role:null,//admin || branch_nanager
      execute: null,
      write: null,
      read:null,
     },
     UserApplicationForm:{
      
     }
  
})
  
const theme = createTheme({
    palette:{
      primary:{
        main:`${UserProfile.CompanyProfile.theme_maincolor}`
      },
      secondary:{
        main:`${UserProfile.CompanyProfile.theme_subcolor}`
      },
      info:{
        main:`${UserProfile.CompanyProfile.theme_decoration_color}`
      },
      // mode: `${Appstate.dark_theme===true?'dark':'light'}`,
      // mode: 'dark',
      background:{
        //paper:'#090B23',
        //default:'#090B23'
      }
    },
    typography:{
      //fontFamily:'Comic Sans Ms',
      body1:{
        fontSize:'1.1rem',
      
      },
      // h1:{
      //   color:'red'
      // }
    },
    shape:{
      borderRadius:0,
    },
    //spacing:8,
    overrides:{
      MuiButton:{
        root:{
          textTransform:'none',
          
        }
      },
  
    },
    props:{
      MuiButton:{
        disableRipple:true,
        // variant:'text'
      },
      MuiButtonGroup:{
        disableRipple:true,
        // variant:'outlined'
      },
      MuiTextField:{
        InputLabelProps:{
          shrink:true
        }
      }
      
    }
  
})   

var ResponsiveMediaQuery={
  mobile_xs:mobile_xs,
  tablet_sm:tablet_sm,
  desktop_md:desktop_md,
  desktop_lg:desktop_lg,
  desktop_xl:desktop_xl
}

const Location = useLocation()

const {pathname} = Location


useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(0)//scroll to top of the component
HttpServerCallFunction({
      server_ip:`${Applicationstate.server_ip}api/user/application_mounting`,
      server_payload:{},
      authentication_token:Applicationstate.authentication_token
}).then(function(JsonResponse){
      const {server_status,server_message}=JsonResponse  
      // SET_SERVER_STATUS(server_status)
      // SET_SERVER_MESSAGE(server_message)
  
      if(server_status===200){
           const {data} = JsonResponse

          //  const {application_form} = data

          //  SET_USER_APPLICATION_FORM(application_form)
          //  SET_AUTHENTICATED(true)

          setUserProfile({...UserProfile,UserApplicationForm:data})
          setApplicationstate({...Applicationstate,authenticated:true})

      }else if(server_status===401){

        //SET_AUTHENTICATED(false)
  
        //Navigate(`/application/login`,{state:{AUTHENTICATION_MESSAGE:`your session has expired,login again.`}})
    
        
  
      }
  
    
  
    
  })
  
  
  
  
  
  
return()=>{//<<<<<<<<<<<----------------Component Will Unmount
  
  
  
  
}
  //eslint-disable-next-line react-hooks/exhaustive-deps 
},[Applicationstate.authenticated])
  



  
return(
<Applicationcontext.Provider value={{UserProfile:UserProfile,ResponsiveMediaQuery:ResponsiveMediaQuery,theme:theme,Applicationstate:Applicationstate}}>

 <div className="App" id={mobile_xs===true||tablet_sm===true?'MOBILE_TABLET_VIEW':'DESKTOP_VIEW'}>
  <header className="top-header">
    <nav className="navbar header-nav navbar-expand-lg">
      <div className="container-fluid" id='top-brandname-frame'>
        
        <a className="navbar-brand" href="/">
          <img src="logo.png" alt="image" style={{width:60,height:50}}/>

          <b className={mobile_xs===true||tablet_sm===true?'mobile-brandname':'desktop-brandname'}> {UserProfile.CompanyProfile.company_long_name} </b> 
            <br />
      
        </a>

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-wd" aria-controls="navbar-wd" aria-expanded="false" aria-label="Toggle navigation">
          <span />
          <span />
          <span />
        </button>

        <div className="collapse navbar-collapse justify-content-end" id="navbar-wd">
           <ul className="navbar-nav" id='top-navigation-link' style={{backgroundColor:UserProfile.CompanyProfile.theme_subcolor}}>
            <li><a className={pathname==='/'?'nav-link active':'nav-link'} href='/'> Home</a></li>

            <li><Link to="/programme" className={pathname==='/programme'?'nav-link active':'nav-link'}>Programmes</Link></li>
           
            <li><Link to="/short_course" className={pathname==='/short_course'?'nav-link active':'nav-link'}>Short Courses</Link></li>

            <li><Link to="/research" className={pathname==='/research'?'nav-link active':'nav-link'}>research</Link></li>
           
            <li><Link to="/consultancy" className={pathname==='/consultancy'?'nav-link active':'nav-link'}>consultancy</Link></li>
            
            <li><Link to="/stock_market" className={pathname==='/stock_market'?'nav-link active':'nav-link'}>Stocks Markets Trends</Link></li>
            
            <li><Link to="/events+news" className={pathname==='/events+news'?'nav-link active':'nav-link'}>News & Events</Link></li>


            <li><Link to="/about" className={pathname==='/about'?'nav-link active':'nav-link'}>about us</Link></li>
           
           
             <li><Link to="/contact" className={pathname==='/contact'?'nav-link active':'nav-link'}>contact</Link></li>
             
             <li><Link to="/application" className={pathname==='/application'?'nav-link active':'nav-link'}>Apply</Link></li>
         
         
         
          </ul>
        </div>

         
        
      </div>
    </nav>
  </header>


  <RouteNavigation />


  <div className="container">
      <div className="row">

         <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="heading_main text_align_left">
         
             </div>
          <div className="full float-right_img">
   
          </div>
        </div>

       </div>
       </div> 




  {/* Start Footer */}
  <footer className="footer-box">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div className="footer_blog">
            <div className="full margin-bottom_30">
              <img src="logo.png" alt="#" />
            </div>
            <div className="full white_fonts">
              <p>
                
              Mwanza Institute of Financial Securities (MIFS) is a financial training center that engages 
              deeply in the provision of a comprehensive training in analyzing and conducting research on 
              ........
              
              
              
               </p>
                  
                  
             </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div className="footer_blog footer_menu white_fonts">
            <h3>Popular Tags</h3>
            <ul> 
              <li><a href="#">&gt; Broker</a></li>
              <li><a href="#">&gt; Derivatives</a></li>
              <li><a href="#">&gt; Government Bond yield</a></li>
              <li><a href="#">&gt; Capital Markets</a></li>
              <li><a href="#">&gt; Financial journalism</a></li>
              <li><a href="#">&gt; Mining journalism</a></li>
              <li><a href="#">&gt; Stock Exchange </a></li>
              <li><a href="#">&gt; Investors</a></li>
              <li><a href="#">&gt; Travel and Tourism Journalism</a></li>
            </ul>
          </div>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div className="footer_blog full white_fonts">
            <h3>Newsletter</h3>
            <p>Subscribe to our news letter to get a quick quote about treanding business technologies and more</p>
            <div className="newsletter_form">
              <form action="index.html">
                <input type="email" placeholder="Your Email" name="#" required />
                <button>Submit</button>
              </form>
            </div>
          </div>
        </div>	 

        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
          <div className="footer_blog full white_fonts">
            <h3>Contact us</h3>
            <ul className="full">
              <li><img src="images/i5.png" /><span>{UserProfile.CompanyProfile.OFFICE_LOCATION.office_address}{UserProfile.CompanyProfile.OFFICE_LOCATION.region},{UserProfile.CompanyProfile.OFFICE_LOCATION.country}</span></li>
              <li><img src="images/i6.png" /><span>{UserProfile.CompanyProfile.CONTACT.email_one}</span></li>
              <li><img src="images/i6.png" /><span>{UserProfile.CompanyProfile.CONTACT.email_two}</span></li>
              <li><img src="images/i7.png" /><span>{UserProfile.CompanyProfile.CONTACT.phone_number1}</span></li>
              <li><img src="images/i7.png" /><span>{UserProfile.CompanyProfile.CONTACT.phone_number2}</span></li>
            </ul>
          </div>
        </div>	 
      </div>
    </div>
  </footer>
  {/* End Footer */}
  <div className="footer_bottom">
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div id='developer-footer'>
          
          <span> ©Copyrights {new Date().getFullYear()} Mwanza Institute of Financial Securities </span>
         
           <span><a href='https://wiflexi.com' target='_blank'>developer williamjonasan@gmail.com</a></span>  
          </div>
        </div>
      </div>
    </div>
  </div>
  <a href="#" id="scroll-to-top" className="hvr-radial-out"><i className="fa fa-angle-up" /></a>
</div>
 


</Applicationcontext.Provider>
);
}

export default App;
