import React ,{Fragment,useEffect,useContext,useRef,useState,createRef}from 'react';
import './SignupForm.scss';
import Grid from '@mui/material/Grid';
import {Applicationcontext} from "../../../applicationcontext";
import {HttpServerCallFunction} from "../../../HttpServercall";
import {ImageValidatorFunction} from "../../../SharedFunction";
import {Typography} from '@mui/material';
import {Cancel} from '@mui/icons-material';
import {Delete,Done} from "@mui/icons-material";
import {Button,TextField} from '@mui/material';
import {useLocation,useParams,useNavigate,Link} from "react-router-dom";
import Alert from '@mui/material/Alert';
import {MoonLoader} from "react-spinners";









  
  






  const  SignupForm=({navigatetoLoginPage=function(){alert('pass navigatetoLoginPage')}})=> {

    const COMPONENT_PRIMARY_KEY = 'ADD_STOCK_VIEW'

    const DATABASE_ACCESS_KEY = 'ADD_STOCK_VIEW'

    const ApplicationState = useContext(Applicationcontext)

    const {theme,ResponsiveMediaQuery,UserProfile,Applicationstate} = ApplicationState
    


    const {CompanyProfile,Permission} = UserProfile

    

    const {theme_maincolor} = CompanyProfile

    // const {role,user_type} = Permission

    const {server_ip,authentication_token} = Applicationstate

    // const {mobile_xs,tablet_sm} = ResponsiveMediaQuery

    const Location = useLocation()

    const {pathname} = Location

    const UrlParameter = useParams()

    const Navigate = useNavigate()
   
    var FormAction = pathname.split('/').slice(-1)[0]==='add'?'add':'update'
    

  const [VALID_ADDSTOCK_STATE,SET_VALID_ADDSTOCK_STATE] = useState(()=>{
    var validstate = false
    const {state} = Location
    if(FormAction==='add'){
      if(state !==null && state !==undefined){
        const {LinkFrom} = state
        if(LinkFrom==='view_store'){
          const {data} = state
          if(Object.keys(data).length > 0){
            validstate = true
          }

        }
      
      }

    }

    return validstate
  })
  



    
  const UploadPassportPhotoRef = useRef()

  const UploadCvAttachmentRef = useRef()

  const UploadCertificateRef = useRef()

  const DEFAULT_PASSPORT_PHOTO = 'passportsize_placeholder.PNG'




  const [USER_APPLICATION_FORM,SET_USER_APPLICATION_FORM]=useState(
    {
      username_email:'',
      password1:'',
      password2:'',
      passport_photo:DEFAULT_PASSPORT_PHOTO,
      full_name:'',
      email:'',
      phone_number:'',
      course_type:'0',
      entry_program:'0',
      cv_attachment:[
        // {fileBlob:'CURICURM ATTACHMENT CV',file_name:'CURICURM ATTACHMENT CV',file_type:'pdf',file_size:'12MB'}
      ],
      certificate_attachments:[
        // {fileBlob:'',file_name:'',file_type:'',file_size:''}
      ],
    }
)



const [VALIDATION_MESSAGE,SET_VALIDATION_MESSAGE] = React.useState(null)



const [SERVER_LOADING,SET_SERVER_LOADING] = React.useState(false)

const [SERVER_MESSAGE,SET_SERVER_MESSAGE] = React.useState(null)

const [SERVER_STATUS,SET_SERVER_STATUS] = React.useState(null)


const [AUTHENTICATED,SET_AUTHENTICATED] = React.useState(false)

  
  



const [ImageGallerySpecificationForm,setImageGallerySpecificationForm]=useState({
     image:DEFAULT_PASSPORT_PHOTO,
     caption_description:''
   }
)   

const [DATAFETCH_LOADING ,SET_DATAFETCH_LOADING] = React.useState(true)
  
    
  
  const [CurrentClickedImage,setCurrentClickedImage] = useState({image_type:'',image_index:null})
  
  const [ServerLoading,setServerLoading] = useState(false)
  

  
  const [SALE_QUANTITY_UNIT,SET_SALE_QUANTITY_UNIT] = useState([
      'pc',
      'set',
      'package',
      'catton',
      'box'
  ])
  
const [SEARCH_FILTER ,SET_SEARCH_FILTER] = React.useState({
    search_query:'',
    search_by:'name',
    brand_name:'general',
    category1:'general',
    selected_storeshop:'general',
    filter_zero_negative_stock:'all',
  
  })

  const [SHORT_COURSE_LIST ,SET_SHORT_COURSE_LIST] = React.useState([
    'marketing',
    'OCSEE',
    'ACSzEE',
    'accounts&finance',
    'business administration',
    'journalism',
    'Dev Studies',
    'international politics',
    'economics',
  ])

  const [LONG_COURSE_LIST ,SET_LONG_COURSE_LIST] = React.useState([
    'marketing',
    'OCSEE',
    'ACSzEE',
    'accounts&finance',
    'business administration',
    'journalism',
    'Dev Studies',
    'international politics',
    'economics',
  ])





function extractDate(dateString) {
    return dateString.substring(2, 10);
 }

  
 


  const {PRODUCT_ID,STORE_ID} = UrlParameter
  
useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(0)//scroll to top of the component








return()=>{//<<<<<<<<<<<----------------Component Will Unmount



}
//eslint-disable-next-line react-hooks/exhaustive-deps 
},[])


useEffect(()=>{//----------COMPONENT DID MOUNT
  //$(window).scrollTop(0)//scroll to top of the component
  
  
  
   
      
return()=>{//<<<<<<<<<<<----------------Component Will Unmount
  
  
  
     
  
}
  //eslint-disable-next-line react-hooks/exhaustive-deps 
},[USER_APPLICATION_FORM.product_or_service,USER_APPLICATION_FORM.category1,USER_APPLICATION_FORM.category2])
  



function validateFileUpload(file) {
  const allowedExtensions = ['pdf', 'doc', 'docx', 'dot', 'dotx'];
  const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

  if (file) {
      const fileSize = file.size;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2) + ' MB';

      if (allowedExtensions.includes(fileExtension)) {
          if (fileSize <= maxFileSize) {
              // alert('File is valid');
              return { valid: true, fileExtension: fileExtension, fileSize: fileSizeInMB };
          } else {
              alert('File size exceeds 10MB');
              return { valid: false, message: 'File size exceeds 10MB' };
          }
      } else {
          alert('Invalid file type. Only PDF and Microsoft Word documents are allowed.');
          return { valid: false, message: 'Invalid file type' };
      }
  } else {
      alert('No file selected');
      return { valid: false, message: 'No file selected' };
  }
}







 
let ImageInputRef = createRef()  

 



const onchangeUploadCvFunction = (event) => {
  const fileInput = event.target;  // Reference to the file input element
  const FILE = fileInput.files[0];
  const FILE_NAME = FILE.name;
  const FILE_SIZE = FILE.size;
  const FILE_TYPE = FILE.type;

  const FileuploadValidation = validateFileUpload(FILE);
  const { valid, fileExtension, fileSize } = FileuploadValidation;
  if (valid) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const ReadyFileblob = fileReader.result;
      
      USER_APPLICATION_FORM.cv_attachment.push({
        fileBlob: ReadyFileblob,
        file_name: FILE_NAME,
        file_type: fileExtension,
        file_size: fileSize
      });
      
      SET_USER_APPLICATION_FORM({ ...USER_APPLICATION_FORM });

      // Reset file input after file upload
      fileInput.value = '';
    };

    fileReader.onerror = () => {
      alert('Failed to read file');
    };

    fileReader.readAsDataURL(FILE);
  } else {
    alert('File not valid, please upload a valid size and type');
  }
};


const onchangeUploadCertificateFunction=(event)=>{ //Listen for Image Selection Change
  const fileInput = event.target;  // Reference to the file input element
  const FILE = fileInput.files[0];
  const FILE_NAME = FILE.name;
  const FILE_SIZE = FILE.size;
  const FILE_TYPE = FILE.type;

  const FileuploadValidation = validateFileUpload(FILE);
  const { valid, fileExtension, fileSize } = FileuploadValidation;
  if (valid) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const ReadyFileblob = fileReader.result;
      
      USER_APPLICATION_FORM.certificate_attachments.push({
        fileBlob: ReadyFileblob,
        file_name: FILE_NAME,
        file_type: fileExtension,
        file_size: fileSize
      });
      
      SET_USER_APPLICATION_FORM({ ...USER_APPLICATION_FORM });

      // Reset file input after file upload
      fileInput.value = '';
    };

    fileReader.onerror = () => {
      alert('Failed to read file');
    };

    fileReader.readAsDataURL(FILE);
  } else {
    alert('File not valid, please upload a valid size and type');
  }
  

} 









const ImagesReader = new FileReader()
const onchangeImagesUpload = (event) => {
  const image = event.target.files[0]

  try {
  const imageName =  image.name  
  const imageSize = image.size
  const imageType = image.type
  var imageBlob


  var Validator = ImageValidatorFunction(imageSize,imageType)
  if(Validator.validSize ===true && Validator.validType===true){//------------Process valid image
    ImagesReader.readAsDataURL(image)

    var ImageTimer = setInterval(function(){

      
      if(JSON.stringify(ImagesReader.result).length > 5){
        imageBlob = ImagesReader.result
        
        if(CurrentClickedImage.image_type==="passport_photo-upload"){
          SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,passport_photo:imageBlob})

          setTimeout(function(){

            document.getElementById('#image-file-input').click()


          },20)
          

        }if(CurrentClickedImage.image_type==="image-gallery-upload"){
          
          setImageGallerySpecificationForm({...ImageGallerySpecificationForm,image:imageBlob})
          
          setTimeout(function(){
            document.getElementById('#image-file-input').click()
  
  
            },20)
        }
     

        clearInterval(ImageTimer)
      }




    },500)



  }else{

    // Notifications()

    alert("Image format has to be png,gif,jpeg and size less than 4Mb,warning7*1000")
  }
    
  }catch (error) {//---ERROR ARE BEING CATCHED
    console.log("SOMETHING_WENT_WRONG_WHILE_PROCESSING_IMAGE")
    
  }

  

};


const triggerImageUploadFunction=(imageType,imageIndex)=>{
    
   setCurrentClickedImage({image_type:imageType,image_index:imageIndex})
   UploadPassportPhotoRef.current.click()
};
    

const RemoveMainImage = (event) => {

  SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,passport_photo:DEFAULT_PASSPORT_PHOTO})

          
};




const onchangeSingleValueFunction=(event)=>{
  const {checked,name,type,value} = event.target
  if(type==='checkbox'){

   SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:checked})

  }else if(type==='number'){

    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:parseFloat(value)})

  }else{

    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:value})
 
   }

};





const onBlurFormFieldFunction=(event)=>{
  const {checked,name,type,value} = event.target
  const PROFIT_MARGIN = (USER_APPLICATION_FORM.sale_price - USER_APPLICATION_FORM.cost_price)
  SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,'profit_margin':PROFIT_MARGIN})
 
 
};










const ProductFormValidationFunction=()=>{
  return new Promise(function(resolve,reject){
   var  JsonResponse = {status:200,message:null}
   if(`${USER_APPLICATION_FORM.passport_photo}`.length < 100){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'UPLOAD PASSPORT PHOTO'

  }else if(USER_APPLICATION_FORM.full_name.length <= 5){
      
      JsonResponse['status'] = 500
      JsonResponse['message'] = 'enter valid full name'

  }else if((!`${USER_APPLICATION_FORM.email}`.includes('@')) || (USER_APPLICATION_FORM.email.length < 5 )){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'enter valid email'

  }else if(USER_APPLICATION_FORM.phone_number.length < 10){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'enter valid phone number'

  }else if(USER_APPLICATION_FORM.course_type==='0'){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'select course type'

  }else if(USER_APPLICATION_FORM.entry_program==='0'){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'select entry program'

  }else if(USER_APPLICATION_FORM.cv_attachment.length===0){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'upload cv attachent'

  }
  else if(USER_APPLICATION_FORM.certificate_attachments.length===0){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'upload certificates attachment'

  }
  else{
    JsonResponse['status'] = 200
    JsonResponse['message'] = null

   }
   return resolve(JsonResponse)
  })
}

const UserRegistrationValidationFunction=()=>{
  return new Promise(function(resolve,reject){
   var  JsonResponse = {status:200,message:null}

   if(`${USER_APPLICATION_FORM.username_email}`.length < 5){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL IN USERNAME'

  }else if(USER_APPLICATION_FORM.full_name.length <= 5){
      
      JsonResponse['status'] = 500
      JsonResponse['message'] = 'FILL IN VALID FULL NAME'

  }else if((USER_APPLICATION_FORM.password1.length<4)){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL IN STRONG PASSWORD'

  }else if((USER_APPLICATION_FORM.password2.length < 4)){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL REPEAT PASSWORD'

  }else if((USER_APPLICATION_FORM.password1!==USER_APPLICATION_FORM.password2)){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'passwords do not much.'

  }
  
  else{
    JsonResponse['status'] = 200
    JsonResponse['message'] = null

   }
   return resolve(JsonResponse)
  })
}


const FormDataSubmisionFunction=(actionObject)=>{
  console.log(USER_APPLICATION_FORM)
 ProductFormValidationFunction().then(function(JsonResponse){
   const {status,message} = JsonResponse
   SET_VALIDATION_MESSAGE(message)
   if(status===200){

    HttpServerCallFunction({
      server_ip:`${server_ip}api/user/user_application_submission`,
      authentication_token:authentication_token,
      server_payload:USER_APPLICATION_FORM
    }).then(function(JsonResponse){
      const {server_status,server_message}=JsonResponse
   



    
    })
  



    
  }else{

    // toast.info(`(app_error) ${message}`,{
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose:4000,
    //   theme:theme.palette.mode==='light'?'colored':'dark',
    //   // toastId:mobile_xs===true || tablet_sm===true?'mobile-toast-container':'desktop-toast-container'
    // });
    
  }
  
 })

}






const onchangeStoreQuantityFunction=(event)=>{
  const {checked,name,type,value} = event.target
  const Index = parseInt(name)

  USER_APPLICATION_FORM.storeshop_information[Index].stock_quantity = parseInt(value)
  SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})

};





const CATEGORY1_AUTOCOMPLETE_ARRAY = []


const imageInsert = () =>{
  ImageInputRef.click()
    
  }


  const DeleteCVFunction = (index) =>{

    USER_APPLICATION_FORM.cv_attachment.splice(index,1)
    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})
      
  }

 
  
  const DeleteCertificateFunction = (index) =>{

    USER_APPLICATION_FORM.certificate_attachments.splice(index,1)
    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})
      
  }
  
  






const SHORT_COURSE_LIST_MAP = SHORT_COURSE_LIST.map(function(course_name, index){
return(
  <option key={`SHORT_COURSE_LIST_MAP${index}`} style={{fontSize: 18}} value={course_name}>{course_name}</option>
)});


const LONG_COURSE_LIST_MAP = LONG_COURSE_LIST.map(function(course_name, index){
  return(
    <option key={`SHORT_COURSE_LIST_MAP${index}`} style={{fontSize: 18}} value={course_name}>{course_name}</option>
)});
  
const CV_ATTACHMENT_MAP = USER_APPLICATION_FORM.cv_attachment.map(function(object,index){
return(
      <Fragment key={`{CV_ATTACHMENT_MAP}${index}`}>
        <div className='FileAttachmentCard'>
         <Grid container spacing={1}> 
         
            <Grid item xs={8} md={10}> <a href='' key={`SHORT_COURSE_LIST_MAP${index}`}>{object.file_name}</a></Grid>
            <Grid item xs={2} md={1}> <img src={`images/files_type/${object.file_type}.svg`} style={{width:30,height:30}} /> </Grid>
            <Grid item xs={2} md={1}><div onClick={()=>{DeleteCVFunction(index)}}><Delete /> </div></Grid>

          </Grid>
        </div>
      </Fragment>
)});

const CERTIFICATE_ATTACHMENT_MAP = USER_APPLICATION_FORM.certificate_attachments.map(function(object,index){
  return(
        <Fragment key={`{CERTIFICATE_ATTACHMENT_MAP}${index}`}>
          <br />
          <div className='FileAttachmentCard'>
           <Grid container spacing={1}> 
           
              <Grid item xs={8} md={10}> <a href='' key={`SHORT_COURSE_LIST_MAP${index}`}>{object.file_name}</a></Grid>
              <Grid item xs={2} md={1}> <img src={`images/files_type/${object.file_type}.svg`} style={{width:30,height:30}} /> </Grid>
              <Grid item xs={2} md={1}><div onClick={()=>{DeleteCertificateFunction(index)}}><Delete /> </div></Grid>
  
            </Grid>
          </div>
        </Fragment>
)});
      
    
      
  



const SignupFunction=()=>{


  UserRegistrationValidationFunction().then(function(JsonResponse){
    const {status,message} = JsonResponse
    SET_VALIDATION_MESSAGE(message)
    console.log(USER_APPLICATION_FORM,JsonResponse)
    SET_SERVER_LOADING(true)
    if(status===200){
      HttpServerCallFunction({
        server_ip:`${server_ip}api/authentication/user/registration`,
        server_payload:USER_APPLICATION_FORM
      }).then(function(JsonResponse){
        const {server_status,server_message}=JsonResponse
        SET_SERVER_LOADING(false)
        SET_SERVER_STATUS(server_status)
        SET_SERVER_MESSAGE(server_message)
    
        console.log(JsonResponse)
    
          //Cookies.set(AUTHENTICATION_TOKEN_NAME, authentication_token, { expires:LoginFormData.keep_login===true?14:7, path: '/' });
    
    
      
      })
    }


  })





}





  
   
return ( //------------------- RETURN HTML
<Fragment> 
<div id='SignupForm' style={{backgroundImage:'url(images/signup-bg-photo.jpg)'}}>
<Grid container spacing={2} className='FormLeftRightPaddingFrame'> 

  <Grid item xs={12}> {/*------------ FORMS SECTION ROW ------------------*/}
      <div id='UserFormBackgroundFrame' style={{border:`1px solid ${theme_maincolor}`}}>
      <form noValidate autoComplete="off" action="" method="post" id='ProductFormElement'>  
        <Grid container spacing={1}> 
        <Grid item xs={12}> 
           <div className='LoginFromFrame'>

           <Grid container spacing={1}> 

           <Grid item xs={12}> 
            <div className='LoginHeaderFrame'>
  
                <Typography variant='h6'>SIGN UP TO APPLY </Typography>
              

            </div>
           </Grid>

           <Grid item xs={12}> 
           <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label='username_email' 
                variant='filled' 
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.username_email}
                name='username_email'
                fullWidth
                size='small'
                InputLabelProps={{
                 shrink: true
               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.username_email !==null && JSON.stringify(USER_APPLICATION_FORM.email).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
           </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

           <Grid item xs={12}> 
           <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label='full_name' 
                variant='filled' 
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.full_name}
                name='full_name'
                fullWidth
                size='small'
                InputLabelProps={{
                 shrink: true
               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.full_name !==null && JSON.stringify(USER_APPLICATION_FORM.full_name).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
           </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

           <Grid item xs={12}> 
           <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label='password' 
                variant='filled' 
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.password1}
                name='password1'
                fullWidth
                size='small'
                InputLabelProps={{

                 shrink: true

               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.password1 !==null && JSON.stringify(USER_APPLICATION_FORM.password1).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
           </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

           <Grid item xs={12}> 
           <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label='repeat password' 
                variant='filled' 
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.password2}
                name='password2'
                fullWidth
                size='small'
                InputLabelProps={{

                 shrink: true

               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.password2 !==null && JSON.stringify(USER_APPLICATION_FORM.password1).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
           </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

           <Grid item xs={12}>
              <div className='validationFrame'>

                {SERVER_STATUS === 200 && SERVER_MESSAGE!==null?<Alert severity='success'> {SERVER_MESSAGE} </Alert>:null}
                
                {SERVER_STATUS > 200 && SERVER_MESSAGE!==null?<Alert severity="error"> {SERVER_MESSAGE} </Alert>:null}
              
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className='validationFrame'>
              
                {VALIDATION_MESSAGE!==null?<Alert severity="error"> {VALIDATION_MESSAGE} </Alert>:null}
              
              </div>
             </Grid>


           <Grid item xs={12}> 
            <div className='LoginButtonFrame'>
              <Button variant='contained' disabled={SERVER_LOADING?true:false} onClick={SignupFunction} size='large'>Signup <MoonLoader size={25} loading={SERVER_LOADING?true:false} />&nbsp; </Button>

              <Button onClick={()=>{navigatetoLoginPage()}} variant='outlined' size='large'>Login</Button> 

            </div>
           </Grid>




             </Grid>
           </div>
        </Grid>
            </Grid> 
      </form>
    </div>
 </Grid> 
</Grid>
</div>
</Fragment>
);
}
export {SignupForm};
    
    