import React ,{Fragment,useEffect,useContext}from 'react';
import $ from 'jquery';
import './ResearchPage.scss';
import {useLocation} from "react-router-dom";
import {TopBanner} from "../TopBanner/TopBanner";
import Grid from '@mui/material/Grid';
import {Link} from "react-router-dom";
import {Applicationcontext} from "../../applicationcontext";







const  ResearchPage=({display_topbanner=true,DisplayFull=true}) => {

  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor} = CompanyProfile
  

  
  






const Location = useLocation()



const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component


return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps







return ( ///-------------- RETURN HTML------------------
<Fragment>
{display_topbanner===true?<div className='topfooter'></div>:null}
   
{display_topbanner===true?<TopBanner TitleName={'RESEARCH'}/>:null}   
<div className="section margin-top_50" id='ResearchPage'>
  <div className="container-padding">

   <div className="row">
       <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Research</span> in Financial Markets</h2>
          </div>
          <div className="full" id='explore-course'>
            <span>
            Financial markets play an important role in driving the country’s economic growth and offering 
            countless opportunities for investors to tap into specific markets and services. But with changing
             regulatory demands and consumer requirements, financial services companies and organizations 
             need unique insights to keep up with this changing industry. We carry out financial market
              research that enables the industry to gain meaningful insights into the domestic and international
               markets. Strategically, financial market research provides actionable insights regarding various 
               financial instruments like portfolio pricing, risk management, etc. Research findings offer
                investors solid strategies that take on board previous, present, and future investments 
                projections.

              </span>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="full">
          <img src="images/research-finance.jpg" alt="#" />
        </div>
      </div>
    
    
    </div>

    </div>


</div>


     {/* section */}
     <div className="section contact_section" style={{background: '#12385b'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="heading_main text_align_left">
            <h2><span>A Unique Professional Training Centre <br /> {UserProfile.CompanyProfile.company_motto}</span></h2>
          </div>
           <div className="full float-right_img">
            <img src="images/reseaecr-footer.jpg" alt="#" />
          </div>
        </div>

        <div className="layout_padding col-lg-6 col-md-6 col-sm-12">
          <div className="contact_form">
            <form action="contact.html">
              <fieldset>
                <div className="full field">
                  <input type="text" placeholder="Your Name" name="your name" />
                </div>
                <div className="full field">
                  <input type="email" placeholder="Email Address" name="Email" />
                </div>
                <div className="full field">
                  <input type="phn" placeholder="Phone Number" name="Phone number" />
                </div>
                <div className="full field">
                  <textarea placeholder="Massage" defaultValue={""} />
                </div>
                <div className="full field">
                  <div className="center"><button>Send</button></div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>			  
    </div>
  </div>
  {/* end section */}

   
</Fragment>
);

// ResearchPage.propTypes = {}
          
// ResearchPage.defaultProps ={}



}
export{ResearchPage}

