import React ,{Fragment,useEffect,useContext}from 'react';
import $ from 'jquery';
import './NewsEventPage.scss';
import {useLocation} from "react-router-dom";
import {TopBanner} from "../TopBanner/TopBanner";
import {Link} from "react-router-dom";
import {Applicationcontext} from "../../applicationcontext";






const  NewsEventPage = ({display_topbanner=true,DisplayFull=true}) => {

  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor} = CompanyProfile
  


  
const [LONG_TERM_COURSE, SET_LONG_TERM_COURSE] = React.useState([
{
 course_title:'Diploma in Applied Digital Marketing for Financial Services', 
 entry_qualification:'Form IV or VI, background in business studies',
 course_content:'This is a hands-on course that has a strong applied focus in which a student is trained to be a professional digital marketer in stock markets',
 duration:'2years involving theoretical and practical training, industrial placement in an organization,  report writing and presentation',
 award:'Professional Diploma' 
},
{
  course_title:'Diploma in Applied Data Science Analytics for Financial Securities  ', 
  entry_qualification:'Form IV or VI, background in business studies',
  course_content:'Learn how Data Science Analytics (Gen AI, ML & ChatGPT) are applied to determine how stock markets investors use the findings to make informed decisions ',
  duration:'2 years involving theoretical and practical training, industrial placement in an organization,  report writing and presentation',
  award:'Professional Certificate' 
 },
 {
  course_title:'Diploma in Business and Financial Journalism', 
  entry_qualification:'Form IV or VI, background in business studies',
  course_content:'Equips you with ethics of journalism, data analytical  skills, research literacy, journalist in financial magazine, institution or become a freelancer',
  duration:'2years involving theoretical and practical training, industrial placement in an organization,  report writing and presentation',
  award:'Professional Diploma' 
 },
 {
  course_title:'Diploma in Financial Markets Analysis', 
  entry_qualification:'Form IV or VI, background in business studies',
  course_content:'Importance of financial markets in the economy, need for analysis and researchers delving into derivatives, options, commodity and currency  markets  operations, focusing on risk management, broking, and investment advisory services',
  duration:'2years involving theoretical and practical training, industrial placement in an organization,  report writing and presentation',
  award:'Professional Diploma' 
 },






]
);

const [SHORT_COURSE_LIST, SET_SHORT_COURSE_LIST] = React.useState([
  {
   course_title:`Mining Journalism`, 
   training_content:`The mining Economy in Tanzania  The Law and Mining in Tanzania The Society, State, Companies and Mining in Tanzania`,
   duration_award:`3 months Jan-March`,
   targeted_participant:`Journalists working in Tanzania`,
   projecte_partners:`Tanzania Chamber of Mines`
  },
  {
    course_title:`Journalistic/Television Reports on Mining`, 
    training_content:`What is Mining Journalism?
    What makes a mining Journalist? 
    Discussion on mining reports and gaps in reporting 
    Reviews of Mining Reports in International and local media
    Planning a Mining News Assignment 
    Researching for Mining Reports
    Interviews for mining Reports?
    Approaches in Structuring Journalistic mining reports!
    Data collection, analysis and presentation for a mining report
    Data journalism and Mining Journalism
    Social accountability in mining journalism
    Practical’s – producing reports for the media.
    `,
    duration_award:`Award: Recognized Professional Certificate`,
    targeted_participant:`
      PR Officers from mining companies
    ,TAWOMA
     ,Journalism students
    `,
    projecte_partners:`
    GGM & other companies
    ,Banks`
   },
   {
    course_title:`Financial Journalism`, 
    training_content:`What is financial journalism?
    Understanding the Stock Markets
    Gain insights into stock markets, bond markets, commodities, and derivatives
    Financial markets exporting
    write accurate and engaging financial news stories, reports, and analyses, adhering to journalistic ethics and standards
    Financial Market Data Analysis
    Gain skills in analyzing financial data, interpreting financial statements using data visualization tools to convey complex information accurately
    Economics interface 
    Understanding economic theories, policies, and their impact on financial markets.
     Business/Economic news reports?
    Evaluation, discussion, observing gaps in  Business and economic News reporting in Tanzania 
    Researching for Business news
    `,
    duration_award:`3 months
    Award: Recognized Professional Certificate
    `,
    targeted_participant:`
    -	Journalists Finance & Accounts Students 
    `,
    projecte_partners:`
    Banks ,
    DSE,
    TIC
    `
   },
   {
    course_title:`Sports Journalism Reporting sports news in a practical and business perspective. 
    `, 
    training_content:`What entails sports Journalism?
    Sport Development in Tanzania
    The Science of Sports/Soccer 
    Sports as a business?
    Researching for sport news
    Interviews for Sport reports
    Sports Coverage reporting in Tanzania 
    Packaging sports News reports for various media 
    Ethics in sports reporting 
    Practical 
    `,
    duration_award:`3 months Award:  Recognized Professional Certificate
    
    `,
    targeted_participant:`
    -	PRO in Banks,
    -	Radio/TV ,
    -	TIC Officers 
    `,
    projecte_partners:`TFF, BMT`
   },
   {
    course_title:`The art of Video Production and its business`, 
    training_content:`What entails Video Production?
    Camera parts and  Functions
    Camera Operations
    Shots and shooting 
    Planning a Shooting 
    Multiple Camera Production
    Audio  devices ,types and their useManagement in Production 
    Light Management in Production 
    Field Recording 
    Studio production 
    Ob Production 
    Editing Video with the grammar 
    Graphics
    Ethics
    Sourcing funds for your production 
    Distribution and marketing 
    `,
    duration_award:`
    3 months
    Award: Recognized Professional Certificate
    `,
    targeted_participant:`
    -	Journalists, 
    -	MCs, 
    -	Form  Four Students and above  
    `,
    projecte_partners:`Video  Production Studios, Events Organizers`
   },
   
  
  
  
  
  
  ]
  );
  
  
  






const Location = useLocation()



const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component







return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps




const LONG_TERM_COURSE_MAP = LONG_TERM_COURSE.map(function(object,index){
return(
<tr key={index}>
  <th scope="row" style={{backgroundColor:theme_subcolor}}>{index+1}</th>
  <td>{object.course_title}</td>
  <td>{object.course_content}</td>
  <td>{object.entry_qualification}</td>
  <td>{object.duration}</td>
  <td>{object.award}</td>
</tr>
)
})

const SHORT_COURSE_LIST_MAP = SHORT_COURSE_LIST.map(function(object,index){
  return(
  <tr key={index}>
    <th scope="row" style={{backgroundColor:theme_subcolor}}>{index+1}</th>
    <td>{object.course_title}</td>
    <td>{object.training_content}</td>
    <td>{object.duration_award}</td>
    <td>{object.targeted_participant}</td>
    <td>{object.projecte_partners}</td>
  </tr>
  )
  })
  
  






return ( ///-------------- RETURN HTML------------------
<Fragment>
{display_topbanner===true?<div className='topfooter'></div>:null}
   
{display_topbanner===true?<TopBanner TitleName={'NEWS & EVENT'}/>:null}   
<div className="section margin-top_50" id='NewsEventPage'>
  <div className="container-padding">

   <div className="row event-card-frame">
       <div className="col-md-7 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Mining Journalism Course 2023/24 </span></h2>
          </div>
          <div className='background-image-frame' style={{backgroundImage:'url(images/consultancy-1.jpg)'}}></div>
           
           
            {/* <div className='poster-detail-frame'>

              <div className='poster-profile'>
                <div className='poster-profile-image' style={{backgroundImage:'url(logo.png)'}}></div>
                 <span>ADMIN</span>
              </div>

               &nbsp;
             <div className='poster-date'>Dec ,2023</div>
              
            </div> */}

            <p>
             Equip participants with mining reporting techniques, visit mining sites, discuss 
             challenges/solutions with artisanal miners, communities & mining officers
             journalists, journalism students, public relations officers, reps of NGO/artisanal 
           </p>

        </div>
      </div>
      <div className="col-md-5">
        <div className="full">
          
        <div className='event-detail-frame'>
            <span><b>Venue : </b> Shinyanga </span>
            <span><b>Organizer Contacts: </b> +255 759 649 673  </span>
            <span><b>Partners : </b> Shinyanga Press Club (SPC), Regional Commissioner, Ministry of Mining</span>
            <span><b>Date due : </b> 5-15 January 2024 </span>
            <span><b>Duration : </b> 2 weeks  </span>
            <span><b>Award  : </b> Recognized Professional Certificate</span>
            <span><b>Participants: </b> : journalists, journalism students, public relations officers, reps of NGO/artisanal miners</span>
            <span><b>Trainers : </b> MIFS, London School of Journalism (LSJ), Menar Academy from South Africa & OSHA</span>
            
            <span><b>Outcome :</b>good understanding of mining law/regulations/operations, objective reporting of mining activities, interlocutor between mining companies and surrounding local communities</span>
            <span><b>Expected Sponsors : </b> Mining Companies, Banks, Tanzania Chamber of Mines, TAWOMA </span>
          </div>
  
        </div>
      </div>
    </div>



    </div>


   </div>
</Fragment>
);

// NewsEventPage.propTypes = {}
          
// NewsEventPage.defaultProps ={}



}
export{NewsEventPage}

