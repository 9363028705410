import React ,{Fragment,useEffect,useContext,useRef,useState,createRef}from 'react';
import './UserApplicationAccount.scss';
import Grid from '@mui/material/Grid';
import {Applicationcontext} from "../../../applicationcontext";
import {HttpServerCallFunction} from "../../../HttpServercall";
import {ImageValidatorFunction} from "../../../SharedFunction";
import {Select,InputLabel,Divider,Typography} from '@mui/material';
import {AddAPhotoOutlined,Cancel,LibraryAddCheck,DeleteForever,Upload,Home,Logout} from '@mui/icons-material';
import {Delete,Done} from "@mui/icons-material";
import {FormControl,Button,TextField,ButtonGroup} from '@mui/material';
import {useLocation,useParams,useNavigate,Link} from "react-router-dom";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import {MoonLoader} from "react-spinners";
import Cookies from 'js-cookie';








  
  






  const  UserApplicationAccount=({navigatetoLoginPageFunction=function(){alert('pass navigatetoLoginPageFunction')}})=> {

    const COMPONENT_PRIMARY_KEY = 'ADD_STOCK_VIEW'

    const DATABASE_ACCESS_KEY = 'ADD_STOCK_VIEW'

    const ApplicationState = useContext(Applicationcontext)

    const {theme,ResponsiveMediaQuery,UserProfile,Applicationstate} = ApplicationState
    


    const {CompanyProfile,Permission,UserApplicationForm} = UserProfile

    

    const {theme_maincolor} = CompanyProfile

    // const {role,user_type} = Permission

    const {server_ip,authentication_token} = Applicationstate

    // const {mobile_xs,tablet_sm} = ResponsiveMediaQuery

    const Location = useLocation()

    const {pathname} = Location

    const UrlParameter = useParams()

    const Navigate = useNavigate()
   

    const [LOGOUT_LOADING,SET_LOGOUT_LOADING] = useState(false)





    
  const UploadPassportPhotoRef = useRef()

  const UploadCvAttachmentRef = useRef()

  const UploadCertificateRef = useRef()

  const DEFAULT_PASSPORT_PHOTO = '/passportsize_placeholder.PNG'




  const [USER_APPLICATION_FORM,SET_USER_APPLICATION_FORM]=useState(
     {...UserApplicationForm,
      username_email:'',
      password1:'',
      password2:'',
      passport_photo:DEFAULT_PASSPORT_PHOTO,
      full_name:'',
      email:'',
      phone_number:'',
      course_type:'0',
      entry_program:'0',
      cv_attachment:[
        // {file:'CURICURM ATTACHMENT CV',file_name:'CURICURM ATTACHMENT CV',file_type:'pdf',file_size:'12MB'}
      ],
      certificate_attachment:[
        // {file:'',file_name:'',file_type:'',file_size:''}
      ],
      application_status:'pending',
      read_status:false,
      application_submited:false,

    }
)



const [VALIDATION_MESSAGE,SET_VALIDATION_MESSAGE] = React.useState(null)



const [SERVER_LOADING,SET_SERVER_LOADING] = React.useState(false)

const [SERVER_MESSAGE,SET_SERVER_MESSAGE] = React.useState(null)

const [SERVER_STATUS,SET_SERVER_STATUS] = React.useState(null)


const [AUTHENTICATED,SET_AUTHENTICATED] = React.useState(false)

  
  



const [ImageGallerySpecificationForm,setImageGallerySpecificationForm]=useState({
     image:DEFAULT_PASSPORT_PHOTO,
     caption_description:''
   }
)   

const [DATAFETCH_LOADING ,SET_DATAFETCH_LOADING] = React.useState(true)
  
    
  
  const [CurrentClickedImage,setCurrentClickedImage] = useState({image_type:'',image_index:null})
  
  const [ServerLoading,setServerLoading] = useState(false)
  

  
  const [SALE_QUANTITY_UNIT,SET_SALE_QUANTITY_UNIT] = useState([
      'pc',
      'set',
      'package',
      'catton',
      'box'
  ])
  
const [SEARCH_FILTER ,SET_SEARCH_FILTER] = React.useState({
    search_query:'',
    search_by:'name',
    brand_name:'general',
    category1:'general',
    selected_storeshop:'general',
    filter_zero_negative_stock:'all',
  
  })

  const [SHORT_COURSE_LIST ,SET_SHORT_COURSE_LIST] = React.useState([
    'marketing',
    'OCSEE',
    'ACSzEE',
    'accounts&finance',
    'business administration',
    'journalism',
    'Dev Studies',
    'international politics',
    'economics',
  ])

  const [LONG_COURSE_LIST ,SET_LONG_COURSE_LIST] = React.useState([
    'marketing',
    'OCSEE',
    'ACSzEE',
    'accounts&finance',
    'business administration',
    'journalism',
    'Dev Studies',
    'international politics',
    'economics',
  ])



  const LogoutCleaningcache=()=>{
    return new Promise(function(resolve,reject){
      var JsonResponse = {status:null,message:null}

      localStorage.removeItem(`active-appxn`)
      localStorage.removeItem(`ux-csrftoken`)
      Cookies.remove(`ux-csrftoken`)
      
})}



  
useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(0)//scroll to top of the component
HttpServerCallFunction({
    server_ip:`${server_ip}api/user/application_mounting`,
    server_payload:{},
    authentication_token:authentication_token
}).then(function(JsonResponse){
    const {server_status,server_message}=JsonResponse  
    SET_SERVER_STATUS(server_status)
    SET_SERVER_MESSAGE(server_message)

    if(server_status===200){
         const {data} = JsonResponse
         const {application_form} = data
         SET_USER_APPLICATION_FORM(application_form)
         SET_AUTHENTICATED(true)
    }else if(server_status===401){
      SET_AUTHENTICATED(false)

      //Navigate(`/application/login`,{state:{AUTHENTICATION_MESSAGE:`your session has expired,login again.`}})
  
      

    }

  

  
})






return()=>{//<<<<<<<<<<<----------------Component Will Unmount




}
//eslint-disable-next-line react-hooks/exhaustive-deps 
},[])


useEffect(()=>{//----------COMPONENT DID MOUNT
//$(window).scrollTop(0)//scroll to top of the component
  

  
   
      
return()=>{//<<<<<<<<<<<----------------Component Will Unmount
  
  
  
     
  
}
  //eslint-disable-next-line react-hooks/exhaustive-deps 
},[])
  



function validateFileUpload(file) {
  const allowedExtensions = ['pdf', 'doc', 'docx', 'dot', 'dotx'];
  const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

  if (file) {
      const fileSize = file.size;
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSizeInMB = (fileSize / (1024 * 1024)).toFixed(2) + ' MB';

      if (allowedExtensions.includes(fileExtension)) {
          if (fileSize <= maxFileSize) {
              // alert('File is valid');
              return { valid: true, fileExtension: fileExtension, fileSize: fileSizeInMB };
          } else {
              alert('File size exceeds 10MB');
              return { valid: false, message: 'File size exceeds 10MB' };
          }
      } else {
          alert('Invalid file type. Only PDF and Microsoft Word documents are allowed.');
          return { valid: false, message: 'Invalid file type' };
      }
  } else {
      alert('No file selected');
      return { valid: false, message: 'No file selected' };
  }
}







 
let ImageInputRef = createRef()  

 



const onchangeUploadCvFunction = (event) => {
  const fileInput = event.target;  // Reference to the file input element
  const FILE = fileInput.files[0];
  const FILE_NAME = FILE.name;
  const FILE_SIZE = FILE.size;
  const FILE_TYPE = FILE.type;

  const FileuploadValidation = validateFileUpload(FILE);
  const { valid, fileExtension, fileSize } = FileuploadValidation;
  if (valid) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const ReadyFileblob = fileReader.result;
      
      USER_APPLICATION_FORM.cv_attachment.push({
        file: ReadyFileblob,
        file_name: FILE_NAME,
        file_type: fileExtension,
        file_size: fileSize
      });
      
      SET_USER_APPLICATION_FORM({ ...USER_APPLICATION_FORM });

      // Reset file input after file upload
      fileInput.value = '';
    };

    fileReader.onerror = () => {
      alert('Failed to read file');
    };

    fileReader.readAsDataURL(FILE);
  } else {
    alert('File not valid, please upload a valid size and type');
  }
};


const onchangeUploadCertificateFunction=(event)=>{ //Listen for Image Selection Change
  const fileInput = event.target;  // Reference to the file input element
  const FILE = fileInput.files[0];
  const FILE_NAME = FILE.name;
  const FILE_SIZE = FILE.size;
  const FILE_TYPE = FILE.type;

  const FileuploadValidation = validateFileUpload(FILE);
  const { valid, fileExtension, fileSize } = FileuploadValidation;
  if (valid) {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      const ReadyFileblob = fileReader.result;
      
      USER_APPLICATION_FORM.certificate_attachment.push({
        file: ReadyFileblob,
        file_name: FILE_NAME,
        file_type: fileExtension,
        file_size: fileSize
      });
      
      SET_USER_APPLICATION_FORM({ ...USER_APPLICATION_FORM });

      // Reset file input after file upload
      fileInput.value = '';
    };

    fileReader.onerror = () => {
      alert('Failed to read file');
    };

    fileReader.readAsDataURL(FILE);
  } else {
    alert('File not valid, please upload a valid size and type');
  }
  

} 









const ImagesReader = new FileReader()
const onchangeImagesUpload = (event) => {
  const image = event.target.files[0]

  try {
  const imageName =  image.name  
  const imageSize = image.size
  const imageType = image.type
  var imageBlob


  var Validator = ImageValidatorFunction(imageSize,imageType)
  if(Validator.validSize ===true && Validator.validType===true){//------------Process valid image
    ImagesReader.readAsDataURL(image)

    var ImageTimer = setInterval(function(){

      
      if(JSON.stringify(ImagesReader.result).length > 5){
        imageBlob = ImagesReader.result
        
        if(CurrentClickedImage.image_type==="passport_photo-upload"){
          SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,passport_photo:imageBlob})

          setTimeout(function(){

            document.getElementById('#image-file-input').click()


          },20)
          

        }if(CurrentClickedImage.image_type==="image-gallery-upload"){
          
          setImageGallerySpecificationForm({...ImageGallerySpecificationForm,image:imageBlob})
          
          setTimeout(function(){
            document.getElementById('#image-file-input').click()
  
  
            },20)
        }
     

        clearInterval(ImageTimer)
      }




    },500)



  }else{

    // Notifications()

    alert("Image format has to be png,gif,jpeg and size less than 4Mb,warning7*1000")
  }
    
  }catch (error) {//---ERROR ARE BEING CATCHED
    console.log("SOMETHING_WENT_WRONG_WHILE_PROCESSING_IMAGE")
    
  }

  

};


const triggerImageUploadFunction=(imageType,imageIndex)=>{
    
   setCurrentClickedImage({image_type:imageType,image_index:imageIndex})
   UploadPassportPhotoRef.current.click()
};
    

const RemoveMainImage = (event) => {

  SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,passport_photo:DEFAULT_PASSPORT_PHOTO})

          
};




const onchangeSingleValueFunction=(event)=>{
  const {checked,name,type,value} = event.target
  if(type==='checkbox'){

   SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:checked})

  }else if(type==='number'){

    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:parseFloat(value)})

  }else{

    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,[name]:value})
 
   }

};





const onBlurFormFieldFunction=(event)=>{
  const {checked,name,type,value} = event.target
  const PROFIT_MARGIN = (USER_APPLICATION_FORM.sale_price - USER_APPLICATION_FORM.cost_price)
  SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,'profit_margin':PROFIT_MARGIN})
 
 
};










const ProductFormValidationFunction=()=>{
  return new Promise(function(resolve,reject){
   var  JsonResponse = {status:200,message:null}
   if(`${USER_APPLICATION_FORM.passport_photo}`.length < 100){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'UPLOAD PASSPORT PHOTO'

  }else if(USER_APPLICATION_FORM.full_name.length <= 5){
      
      JsonResponse['status'] = 500
      JsonResponse['message'] = 'enter valid full name'

  }else if((!`${USER_APPLICATION_FORM.email}`.includes('@')) || (USER_APPLICATION_FORM.email.length < 5 )){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'enter valid email'

  }else if(USER_APPLICATION_FORM.phone_number.length < 10){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'enter valid phone number'

  }else if(USER_APPLICATION_FORM.course_type==='0'){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'select course type'

  }else if(USER_APPLICATION_FORM.entry_program==='0'){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'select entry program'

  }else if(USER_APPLICATION_FORM.cv_attachment.length===0){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'upload cv attachent'

  }
  else if(USER_APPLICATION_FORM.certificate_attachment.length===0){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'upload certificates attachment'

  }
  else{
    JsonResponse['status'] = 200
    JsonResponse['message'] = null

   }
   return resolve(JsonResponse)
  })
}

const UserRegistrationValidationFunction=()=>{
  return new Promise(function(resolve,reject){
   var  JsonResponse = {status:200,message:null}

   if(`${USER_APPLICATION_FORM.username_email}`.length < 5){

    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL IN USERNAME'

  }else if(USER_APPLICATION_FORM.full_name.length <= 5){
      
      JsonResponse['status'] = 500
      JsonResponse['message'] = 'FILL IN VALID FULL NAME'

  }else if((USER_APPLICATION_FORM.password1.length<4)){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL IN STRONG PASSWORD'

  }else if((USER_APPLICATION_FORM.password2.length < 4)){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'FILL REPEAT PASSWORD'

  }else if((USER_APPLICATION_FORM.password1!==USER_APPLICATION_FORM.password2)){
      
    JsonResponse['status'] = 500
    JsonResponse['message'] = 'passwords do not much.'

  }
  
  else{
    JsonResponse['status'] = 200
    JsonResponse['message'] = null

   }
   return resolve(JsonResponse)
  })
}


const FormDataSubmisionFunction=(actionObject)=>{
  console.log(USER_APPLICATION_FORM)
 ProductFormValidationFunction().then(function(JsonResponse){
   const {status,message} = JsonResponse
   SET_VALIDATION_MESSAGE(message)
   SET_SERVER_LOADING(true)
   SET_SERVER_STATUS(null)
   if(status===200){

    HttpServerCallFunction({
      server_ip:`${server_ip}api/user/user_application_submission`,
      authentication_token:authentication_token,
      server_payload:USER_APPLICATION_FORM
    }).then(function(JsonResponse){
      const {server_status,server_message}=JsonResponse
      SET_SERVER_LOADING(false)
      SET_SERVER_MESSAGE(server_message)
      SET_SERVER_STATUS(server_status)

      if(server_status===200){

        SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM,application_submited:true})

      }
   



    
    })
  



    
  }else{

    // toast.info(`(app_error) ${message}`,{
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose:4000,
    //   theme:theme.palette.mode==='light'?'colored':'dark',
    //   // toastId:mobile_xs===true || tablet_sm===true?'mobile-toast-container':'desktop-toast-container'
    // });
    
  }
  
 })

}






const onchangeStoreQuantityFunction=(event)=>{
  const {checked,name,type,value} = event.target
  const Index = parseInt(name)

  USER_APPLICATION_FORM.storeshop_information[Index].stock_quantity = parseInt(value)
  SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})

};





const CATEGORY1_AUTOCOMPLETE_ARRAY = []


const imageInsert = () =>{
  ImageInputRef.click()
    
  }


  const DeleteCVFunction = (index) =>{

    USER_APPLICATION_FORM.cv_attachment.splice(index,1)
    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})
      
  }

 
  
  const DeleteCertificateFunction = (index) =>{

    USER_APPLICATION_FORM.certificate_attachment.splice(index,1)
    SET_USER_APPLICATION_FORM({...USER_APPLICATION_FORM})
      
  }
  
  






const SHORT_COURSE_LIST_MAP = SHORT_COURSE_LIST.map(function(course_name, index){
return(
  <option key={`SHORT_COURSE_LIST_MAP${index}`} style={{fontSize: 18}} value={course_name}>{course_name}</option>
)});


const LONG_COURSE_LIST_MAP = LONG_COURSE_LIST.map(function(course_name, index){
  return(
    <option key={`SHORT_COURSE_LIST_MAP${index}`} style={{fontSize: 18}} value={course_name}>{course_name}</option>
)});
  
const CV_ATTACHMENT_MAP = USER_APPLICATION_FORM.cv_attachment.map(function(object,index){
return(
      <Fragment key={`{CV_ATTACHMENT_MAP}${index}`}>
        <div className='FileAttachmentCard'>
         <Grid container spacing={1}> 
         
            <Grid item xs={8} md={10}> <a href='' key={`SHORT_COURSE_LIST_MAP${index}`}>{object.file_name}</a></Grid>
            <Grid item xs={2} md={1}> <img src={`/images/files_type/${object.file_type}.svg`} style={{width:30,height:30}} /> </Grid>
            <Grid item xs={2} md={1}><div onClick={()=>{DeleteCVFunction(index)}}><Delete /> </div></Grid>

          </Grid>
        </div>
      </Fragment>
)});

const CERTIFICATE_ATTACHMENT_MAP = USER_APPLICATION_FORM.certificate_attachment.map(function(object,index){
  return(
        <Fragment key={`{CERTIFICATE_ATTACHMENT_MAP}${index}`}>
          <br />
          <div className='FileAttachmentCard'>
           <Grid container spacing={1}> 
           
              <Grid item xs={8} md={10}> <a href='' key={`SHORT_COURSE_LIST_MAP${index}`}>{object.file_name}</a></Grid>
              <Grid item xs={2} md={1}> <img src={`/images/files_type/${object.file_type}.svg`} style={{width:30,height:30}} /> </Grid>
              <Grid item xs={2} md={1}><div onClick={()=>{DeleteCertificateFunction(index)}}><Delete /> </div></Grid>
  
            </Grid>
          </div>
        </Fragment>
)});
      
    


const LogoutFunction=()=>{

  Cookies.remove('ux-csrftoken')
  SET_LOGOUT_LOADING(true)
  setTimeout(function(){
   SET_LOGOUT_LOADING(false)
   navigatetoLoginPageFunction()

   },1000)


}
      
  



  
   
return ( //------------------- RETURN HTML
<Fragment> 
<div id='UserApplicationAccountFrame' style={{backgroundImage:'url(images/applicationpage.jpg)'}}>
<div className='BlurBackground'>
<Grid container spacing={2} sx={{display:'flex',flexDirection:'row',justifyContent:'center'}}> 



  <Grid item xs={12} md={5}> {/*------------ FORMS SECTION ROW ------------------*/}
      <div id='UserFormBackgroundFrame' style={{border:`1px solid ${theme_maincolor}`}}>
      <form noValidate autoComplete="off" action="" method="post" id='ProductFormElement'>  
        <Grid container spacing={1}> 

        <Grid item xs={12}> 
           <div className='UserApplicationStatusFrame'>
                <span>application submitted :<b className={USER_APPLICATION_FORM.application_submited?'SuccessForm':'NotSuccessForm'}>{USER_APPLICATION_FORM.application_submited?'submited':'not submitted'}</b></span>
                
                <span>application read :<b className={USER_APPLICATION_FORM.read_status?'SuccessForm':'NotSuccessForm'}>{USER_APPLICATION_FORM.read_status?'read':'unread'}</b></span>
                
                <span>application status : <b className={USER_APPLICATION_FORM.application_status==='approved'?'SuccessForm':'NotSuccessForm'}>{USER_APPLICATION_FORM.application_status}</b> </span>

                {SERVER_STATUS!=null && SERVER_STATUS!==200 && SERVER_STATUS!='offline'?
                 <span className='ServerErrorMessage'> SERVER_ERROR :<b>{SERVER_MESSAGE} : {SERVER_STATUS}</b> </span>
                :null}

                <div className='TopnavigationFrame'>

                  <a href='/'><Button variant='outlined' size='small' startIcon={<Home />}>Home</Button></a>  

                  <Button onClick={LogoutFunction} variant='text' size='small' startIcon={<Logout />}>
                    Logout &nbsp;
                    <MoonLoader size={15} color='blue' loading={LOGOUT_LOADING} />
                  </Button>

                </div>
           </div>
          
           </Grid>
           <Grid item xs={12}> 
          <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField id="product_name_field" 
                label={`full_name`} 
                variant='filled' 
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.full_name}
                name='full_name'
                fullWidth
                size='small'
                InputLabelProps={{
                 shrink: true
               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.full_name !==null && JSON.stringify(USER_APPLICATION_FORM.full_name).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
          </Grid> 
            <div className='form-field-divider'></div>
           </Grid>

          <Grid item xs={12}> 
          <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField id="product_name_field" 
                label={`email`} 
                variant='filled' 
                type='email'
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.email}
                name='email'
                fullWidth
                size='small'
                InputLabelProps={{
                 shrink: true
               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.email !==null && JSON.stringify(USER_APPLICATION_FORM.email).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
          </Grid> 
            <div className='form-field-divider'></div>
          </Grid>

          <Grid item xs={12}> 
          <Grid container spacing={0}>
            <Grid item xs={10} md={10}>
             <TextField 
                label={`phone_number`} 
                variant='filled' 
                type='tel'
                required
                onChange={onchangeSingleValueFunction}
                value={USER_APPLICATION_FORM.phone_number}
                name='phone_number'
                fullWidth
                size='small'
                InputLabelProps={{
                 shrink: true
               }}/>
          </Grid>
           <Grid item xs={2} md={2}>
             <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
                {USER_APPLICATION_FORM.phone_number !==null && JSON.stringify(USER_APPLICATION_FORM.phone_number).length > 4? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#791129'}}  />}
             </div>
          </Grid>
          </Grid> 
            <div className='form-field-divider'></div>
          </Grid>
          
          <Grid item xs={12}> 
          <Grid container spacing={0}>
          <Grid item xs={10} md={10}>
          <FormControl size='medium' variant="filled" fullWidth>
           <InputLabel htmlFor="course_type">course_type</InputLabel>
              <Select
                native
                value={USER_APPLICATION_FORM.course_type}
                label="course_type"
                onChange={onchangeSingleValueFunction}
                inputProps={{
                 name: 'course_type',
                 id: 'course_type'
               }}>
              <option style={{fontSize: 18}} value='0'> short/long course</option>
              <option style={{fontSize: 18}} value='short_course'> short_course</option>
              <option style={{fontSize: 18}} value='long_course'>long_course</option>
             </Select>
          </FormControl>
          </Grid>
           <Grid item xs={2} md={2}>
           <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
            
              {USER_APPLICATION_FORM.course_type !=='0'? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#FF5F1F'}}  />}
           
           </div>
          </Grid>
           </Grid> 
          <div className='form-field-divider'></div>
          </Grid>

          <Grid item xs={12}> 
          <Grid container spacing={0}>
          <Grid item xs={10} md={10}>
          <FormControl size='medium' variant="filled" fullWidth>
           <InputLabel htmlFor="course_type">entry program</InputLabel>
              <Select
                native
                value={USER_APPLICATION_FORM.entry_program}
                label="entry_program"
                onChange={onchangeSingleValueFunction}
                inputProps={{
                 name: 'entry_program',
                 id: 'entry_program'
               }}>
              <option style={{fontSize: 18}} value='0'> select/entry_program</option>
              {USER_APPLICATION_FORM.course_type==='short_course'?SHORT_COURSE_LIST_MAP:null}
              {USER_APPLICATION_FORM.course_type==='long_course'?LONG_COURSE_LIST_MAP:null}
     
             </Select>
          </FormControl>
          </Grid>
           <Grid item xs={2} md={2}>
           <div className='form-validation-wrapper' style={{backgroundColor:theme_maincolor}}>
            {USER_APPLICATION_FORM.entry_program !=='0'? <Done fontSize='large' sx={{color:'#fff'}} />:<Cancel fontSize='large' sx={{color:'#FF5F1F'}}  />}
           </div>
          </Grid>
           </Grid> 
          <div className='form-field-divider'></div>
          </Grid>

          <Grid item xs={12}> 
          <Grid container spacing={1} style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}> 

          <Grid item xs={12} sm={6}><Button  onClick={()=>{triggerImageUploadFunction("passport_photo-upload")}} variant='contained' startIcon={<AddAPhotoOutlined style={{fontSize:25}}/>}>Upload Pasport Size</Button> </Grid>
          
          <Grid item xs={12} sm={4}>
             <div className='coverphoto-image-wrapper' style={{width:'100%',height:'200px'}}>
                  
                  <img src={USER_APPLICATION_FORM.passport_photo} alt=""  style={{opacity:'0.8',maxWidth:'90%',maxHeight:'90%'}}/>
            
            </div>
          </Grid>

           <Grid item xs={12} sm={4}>  <Button onClick={RemoveMainImage} variant='outlined' startIcon={<DeleteForever />}>Remove</Button></Grid>


             </Grid>
           </Grid>  

           <Grid item xs={12}>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant='body2'>UPLOAD CV ATTACHMENT</Typography> 
        </AccordionSummary>
          <AccordionDetails>
            <div className='AttactmentFrame'>
            <Button variant='outlined' startIcon={<Upload />} onClick={()=>{UploadCvAttachmentRef.current.click()}}>upload</Button>
            <br />
             <Divider />
          

             {CV_ATTACHMENT_MAP}
            </div>
         </AccordionDetails>
          </Accordion>
            
             </Grid>

             <Grid item xs={12}>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="upload-certificate"
        >
          <Typography variant='body2'>UPLOAD CERTIFICATES/ATTACHMENT</Typography> 
        </AccordionSummary>
        <AccordionDetails>
            <Button variant='outlined' startIcon={<Upload />} onClick={()=>{UploadCertificateRef.current.click()}}>Upload</Button>
            <br />
             <Divider />
           {CERTIFICATE_ATTACHMENT_MAP}
        </AccordionDetails>
      </Accordion>
            
             </Grid>


             <Grid item xs={12}>
              <div className='validationFrame'>
              
                {VALIDATION_MESSAGE!==null?<Alert severity="error"> {VALIDATION_MESSAGE} </Alert>:null}
              
              </div>
             </Grid>

             <Grid item xs={12}>
              <div className='validationFrame'>

                {SERVER_STATUS === 200 && SERVER_MESSAGE!==null?<Alert severity='success'> {SERVER_MESSAGE} </Alert>:null}
                
                {SERVER_STATUS > 200 && SERVER_MESSAGE!==null?<Alert severity="error"> {SERVER_MESSAGE} {SERVER_STATUS} </Alert>:null}
              
              </div>
            </Grid>




      

          <Grid item xs={12} className='action-button-wrapper'> 
      
            <ButtonGroup disabled={ServerLoading===true?true:false} fullWidth={true} variant="contained" color="primary" className="product-submit-button">
                       
             <Button type='button' disabled={SERVER_LOADING?true:false} onClick={()=>(FormDataSubmisionFunction())}  endIcon={<LibraryAddCheck />}><b style={{fontSize:'1.5rem'}}> submit/apply </b>&nbsp; <MoonLoader size={35} loading={SERVER_LOADING?true:false}/></Button>

            </ButtonGroup>

          <div style={{width:'100%',height:10}}></div>

          </Grid>



    

       
            </Grid> 
      </form>
    </div>
 </Grid> 
 
 {/*------END FORM FIELD SECTION ------*/}



<div style={{display:'none'}}>

   <input onChange={onchangeImagesUpload} type="file"  id="image-file-input" ref={UploadPassportPhotoRef}   />

   {/* <input  onChange={onchangeUploadCvFunction} type="file"  multiple ref={(fileinput)=>{ImageInputRef = fileinput}} style={{display:'none'}} id='file'/>
   */}
   <input  onChange={onchangeUploadCvFunction}  type="file"   ref={UploadCvAttachmentRef} style={{display:'none'}} id='file'/>

   <input  onChange={onchangeUploadCertificateFunction}  type="file"   ref={UploadCertificateRef} style={{display:'none'}} id='file-certificate'/>


</div>        

</Grid>
</div>
</div>
</Fragment>
);
}
export {UserApplicationAccount};
    
    