import React ,{Fragment,useEffect}from 'react';
import {Routes,Route} from 'react-router-dom';
import {Homepage} from "./Components/Homepage/Homepage";
import {AboutPage} from "./Components/AboutPage/AboutPage";
import {ProgrammePage} from "./Components/ProgrammePage/ProgrammePage";
import {ContactPage} from "./Components/ContactPage/ContactPage";
import {ConsultancyPage} from "./Components/ConsultancyPage/ConsultancyPage";
import {StockMarketTrendPage} from "./Components/StockMarketTrendPage/StockMarketTrendPage";
import {ResearchPage} from "./Components/ResearchPage/ResearchPage";
import {ShortCoursePage} from "./Components/ShortCoursePage/ShortCoursePage";
import {NewsEventPage} from "./Components/NewsEventPage/NewsEventPage";
import {ApplicationPage} from "./Components/ApplicationPage/ApplicationPage";




const  RouteNavigation = (props) => {

useEffect(()=>{//COMPONENT DID MOUNT


},[])




  



const PageNotFound =()=>{

return (
<React.Fragment>


    <div className='pagenotfound-wrapper'>


    <h1>404 PAGE NOT FOUND</h1>


    </div>

</React.Fragment>
)
}





return (  //=----RETURN MAIN HTML NAVIGATION
<Fragment>
<Routes>
<Route  path={`/`} element={<Homepage />} />

<Route  path={`/programme`} element={<ProgrammePage />} />

<Route  path={`/short_course`} element={<ShortCoursePage />} />

<Route  path={`/research`} element={<ResearchPage />} />

<Route  path={`/stock_market`} element={<StockMarketTrendPage />} />

<Route  path={`/events+news`} element={<NewsEventPage />} />

<Route  path={`/consultancy`} element={<ConsultancyPage />} />

<Route path={`/about`}   element={<AboutPage />} />

<Route path={`/contact`} element={<ContactPage />} />

<Route path="/application" element={<ApplicationPage />} />

<Route path="*" element={<h1>NO PAGE WAS FOUND .</h1>} />

</Routes>
</Fragment>
);
}
export {RouteNavigation};

