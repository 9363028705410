import React ,{Fragment,useEffect,useContext}from 'react';
import './ContactPage.scss';
import $ from 'jquery';
import {useHistory,useLocation} from "react-router-dom";
import Grid from '@mui/material/Grid/Grid';
import {Applicationcontext} from "../../applicationcontext";
import {TopBanner} from "../TopBanner/TopBanner";
import {Link} from "react-router-dom";



const  ContactPage = ({display_topbanner=true,DisplayFull=true}) => {

  const APPLICATION_STATE = useContext(Applicationcontext)

  const {UserProfile,ResponsiveMediaQuery} = APPLICATION_STATE
  
  const  {CompanyProfile} = UserProfile
  
  const {theme_maincolor,theme_subcolor,CONTACT,SOCIAL_MEDIA,OFFICE_LOCATION} = CompanyProfile





const {state} = Location



useEffect(()=>{ //COMPONENT DID MOUNT
$(window).scrollTop(0)//scroll to top of the component






return()=>{//<<<<<<<<<<<----------------Component Will Unmount




   

}
},[]) // eslint-disable-line react-hooks/exhaustive-deps










return ( ///-------------- RETURN HTML------------------
<Fragment>
{display_topbanner===true?<div className='topfooter'></div>:null}
{display_topbanner===true?<TopBanner  TitleName='Contact & Location' />:null}  
<div className="section margin-top_50">
  <div className="container-padding">
    <div className="row about-wrapper">
       <div className="col-md-6 layout_padding_2">
        <div className="full">
          <div className="heading_main text_align_left">
            <h2><span>Contact</span> & Information</h2>
          </div>
          <div className="full" id='contact-frame'>
               <ul>
                 <li><b>Physical Address:</b></li>
                 <li>
                   
                   3rd Floor Room 301 <br />
                   Nyanza Cooperative Building <br />
                   KCB Bank <br />
                   Kenyatta Road
                   <br />

                   {OFFICE_LOCATION.region} ,
                 
                   {OFFICE_LOCATION.country}
                 </li>
               </ul>

               <ul>
                  <li><b>Postal Address</b></li>
                  <li>{OFFICE_LOCATION.postal_address}<br />
                </li>
              </ul>

              <ul>
                  <li><b>Mobile</b> <br />
                  
                  <a href={`tel:${CONTACT.phone_number1}`}>{CONTACT.phone_number1}<br /></a>
                  <a href={`tel:${CONTACT.phone_number2}`}>{CONTACT.phone_number2}<br /></a>
                  
                  </li>

                  <li><b>Email</b><br /> {CONTACT.email_one} <br />{CONTACT.email_two}</li>
              </ul>

          </div>
        

          <div className="full">
     
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="full" id='location-map-frame'>

       <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d996.4914702457357!2d32.898241116773356!3d-2.5180475509187703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19ce7ba788cba06b%3A0x7aab324110fb4be1!2sNyanza%20Coop.%20Society!5e0!3m2!1sen!2stz!4v1700383941068!5m2!1sen!2stz" width='100%' height='100%' style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />

        </div>
      </div>
    </div>

  </div>
</div>

    


</Fragment>
);

// ContactPage.propTypes = {}
          
// ContactPage.defaultProps ={}



}
export{ContactPage}

